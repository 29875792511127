<template>
<div>

  <p class="text-left" ref="creation">
    This {{dataType}} was created by <em>{{addBy}}</em> on <em>{{addDate | moment("LLLL")}}</em>
  </p>
  <p class="text-left" ref="not-modified" v-if="lastMod == null">
    This {{dataType}} was never modified
  </p>
  <p class="text-left" ref="modified" v-else>
    This {{dataType}} was last modified by <em>{{lastModBy}}</em> on <em>{{lastMod | moment("LLLL")}}</em>
    <span v-if="this.$route.name == 'Contact'" class="clickable float-right text-primary" @click="$bvModal.show('modal-history')"><u>More info</u></span>
  </p>


  <b-modal id="modal-history" size="xl" :title="$t('general.history')" ok-only>
    <Audit></Audit>
  </b-modal>


</div>


</template>

<script>
import Audit from '../components/Audit.vue'

export default {
  name: 'History',

  components: {
    Audit
  },
  
  data() {
    return {
    }
  },
  props: {
    dataType: {
      type: String,
      required: true
    },
    addBy: {
      type: String,
      required: true
    },
    addDate: {
      type: String,
      required: true
    },
    lastModBy: {
      type: String,
      required: true
    },

    lastMod:{

      required: true
    }
  },
  methods: {

    openModal() {
      this.$root.$emit('bv::toggle::modal', 'modal-history', '#btnToggle')
    },

  },
  computed: {

  },
}
</script>



<style scoped>


</style>
