<template>
<div>

  <div class="loader" v-if="loading"></div>
  <b-form-file v-if='!loading' accept=".csv" @input="uploadFile"></b-form-file>

  <div v-show='importedCounter != 0' class='mt-2 green'>
    {{importedCounter}} {{$t('general.importSuccess')}}
  </div>

  <div v-for="(data, index) in updateMaterial" :key="`updateMaterial-${index}`" class="green mt-2">
    {{data}}
  </div>

  <div v-for="(data, index) in warningList" :key="`warningList-${index}`" class="text-warning mt-2">
    {{data}}
  </div>

  <div v-for="(data, index) in errorList" :key="`errorList-${index}`" class="error mt-2">
    {{data}}
  </div>

  <div v-for="(data, index) in errorRegulation" :key="`errorRegulation-${index}`" class="error mt-2">
    {{data}}
  </div>

  <div v-for="(data, index) in errorMaterial" :key="`errorMaterial-${index}`" class="error mt-2">
    {{data}}
  </div>

  <div v-if='newFamilies.length > 0' class='mt-2 warn-families'>
    {{newFamilies.length}} {{$t('general.createdFamilies')}} <br />
    {{this.displayNewFamilies(newFamilies)}}
  </div>

  <div v-if='errorSupplierWithoutDistributor.length != 0' class='mt-2'>
    {{$t('errorMsg.supplierWithoutDistributor')}}
    <div v-for="(data, index) in errorSupplierWithoutDistributor" :key="`data-${index}`" class="error">
      {{data}}
    </div>
  </div>

</div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import VuePapaParse from 'vue-papa-parse'

const SLEEP_MILLISEC = 100
const sleepNow = (delay) => new Promise((resolve) => setTimeout(resolve, delay))

Vue.use(VuePapaParse)
window.axios = require("axios");

export default {
  name: 'ImportCsv',

  props: {
    endpoint: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      loading: false,
      importedData: [],
      importedCounter: 0,
      errorList: [],
      errorSupplierWithoutDistributor: [],
      errorRegulation: [],
      errorMaterial: [],
      updateMaterial: [],
      warningList: [],
      newFamilies: [],
    }
  },

  methods: {
    uploadFile(file) {
      this.loading = true
      this.importedCounter = 0
      this.errorList = []
      this.errorSupplierWithoutDistributor = []

      let _self = this
      this.$papa.parse(file, {
        skipEmptyLines: true,
        header: true,
        complete: function(results) {
          this.importedData = results.data;
          if (_self._props.endpoint == 'contact') {
            _self.postContact(this.importedData)
          }
          if (_self._props.endpoint == 'distributor' || _self._props.endpoint == 'client' || _self._props.endpoint == 'supplier') {
            _self.searchContact(this.importedData)
          }
          if (_self._props.endpoint == 'ingredient') {
            _self.searchIngredient(this.importedData)
          }
          if (_self._props.endpoint == 'material') {
            _self.searchMaterial(this.importedData)
          }
        }
      })
    },

    postContact(data) {
      for (let i = 0; i < data.length; i++) {
        axios
          .post(this._props.endpoint, data[i])
          .then(() => {
            this.importedCounter = this.importedCounter + 1
          })
          .catch((error) => {
            console.log(error.response)
            this.errorList.push(error.response.data._embedded.hashMaps[0])
          })
          .finally(() => {
            this.loading = false
          });
      }
    },

    searchContact(data) {
      let _self = this
      for (let i = 0; i < data.length; i++) {
        //search if contact already in database
        var filter = "/search/findByFirstNameContainingIgnoreCaseOrLastNameContainingIgnoreCaseOrEmailContainingIgnoreCase";
        var paramValue = {
          "firstName": data[i].firstName,
          "lastName": data[i].lastName,
          "email": data[i].email
        };
        axios
          .get("contact" + filter, {
            params: paramValue
          })
          .then((response) => {
            //new contact need to post first
            if (response.data._embedded.contact.length == 0) {
              axios
                .post('contact', data[i])
                .then((response) => {
                  let contactLink = response.data._links.self.href
                  _self.postWithContact(data[i], contactLink)
                })
                .catch((error) => {
                  console.log(error.response)
                })
            } else {
              //contact exist get id and post distributor and add contact
              let contactLink = response.data._embedded.contact[0]._links.self.href
              _self.postWithContact(data[i], contactLink)
            }
          })
          .catch((error) => {
            console.log(error.response)
          })
      }

    },

    postWithContact(data, contactLink) {
      let id
      axios
        .post(this._props.endpoint, data)
        .then((response) => {
          id = response.data._links.self.href.split("/").pop()
          axios
            .put(this._props.endpoint + '/' + id + '/contacts', contactLink, {
              headers: {
                "Content-type": "text/uri-list"
              }
            })
            .then(() => {
              this.importedCounter = this.importedCounter + 1
            })
            .catch((error) => {
              console.log(error.response)
            })
        })
        .catch((error) => {
          console.log(error.response)
          this.errorList.push(error.response.data._embedded.hashMaps[0])
        })
        .finally(() => {
          if (this._props.endpoint == 'supplier') {
            this.putDistributor(data, id)
          }
          this.loading = false
        })
    },

    putDistributor(data, id) {

      var filter = "/search/findByDistributorNameContainingIgnoreCaseOrderByDistributorName";
      var paramValue = {
        "distributorName": data.distributorName,
      };
      axios
        .get("distributor" + filter, {
          params: paramValue
        })
        .then((response) => {
          //new distributor need to post first
          if (response.data._embedded.distributor.length == 0) {
            //create a list of supplier create without distributor.
            //can't add distributor because we would need contact and the import file will ne too long
            //the admin should import distributor list before addind supplier
            this.errorSupplierWithoutDistributor.push(data.supplierName)
          } else {
            //distributor exist get id and put distributor
            let distributorLink = response.data._embedded.distributor[0]._links.self.href
            axios.put('supplier/' + id + '/distributors', distributorLink, {
              headers: {
                "Content-type": "text/uri-list"
              }
            })
          }
        })
    },

    searchIngredient(data) {

      for (let i = 0; i < data.length; i++) {

        //create the ingredient
        let ingredient = [data[i]]
        let j = i + 1
        //add all line with the same ingredient name to the array
        if (i < data.length - 1) {
          while (data[i].name == data[j].name) {
            ingredient.push(data[j])
            j++
            i++
            if (j == data.length) {
              break
            }
          }
        }

        this.postIngredient(ingredient)
      }
      this.loading = false

    },

    async postIngredient(ingredient) {

      let dataIngredient = {
        name: ingredient[0].name,
        casNumber: ingredient[0].casNumber,
        einecsNumber: ingredient[0].einecsNumber,
        synonym: ingredient[0].synonym,
      }
      // concatenate Cas, Einec and synonym if exist
      for (let h = 1; h < ingredient.length; h++) {
        if (ingredient[h].casNumber != "" && dataIngredient.casNumber.toLowerCase().includes(ingredient[h].casNumber.toLowerCase()) == false) {
          dataIngredient.casNumber = dataIngredient.casNumber + "; " + ingredient[h].casNumber
        }
        if (ingredient[h].einecsNumber != "" && dataIngredient.einecsNumber.toLowerCase().includes(ingredient[h].einecsNumber.toLowerCase()) == false) {
          dataIngredient.einecsNumber = dataIngredient.einecsNumber + "; " + ingredient[h].einecsNumber
        }
        if (ingredient[h].synonym != "" && dataIngredient.synonym.toLowerCase().includes(ingredient[h].synonym.toLowerCase()) == false) {
          dataIngredient.synonym = dataIngredient.synonym + "; " + ingredient[h].synonym
        }
      }
      //post the ingredient data
      let response = await axios.post("ingredient", dataIngredient)
      this.importedCounter = this.importedCounter + 1
      //for each row of the ingredient
      for (let i = 0; i < ingredient.length; i++) {
        let newInci = {
          inciName: ingredient[i].inciName,
          obs: ingredient[i].obs,
          ingredient: response.data._links.self.href
        }
        //post INCI of the first line
        if (i == 0) {
          await this.postInci(ingredient, i, newInci)
        }
        if (i != 0) {
          //if it's the SAME regulation AND inci BUT different condition --> add condition
          if (ingredient[i].regulation == ingredient[i - 1].regulation && ingredient[i].inciName == ingredient[i - 1].inciName && ingredient[i].conditional != ingredient[i - 1].conditional) {
            axios.get(response.data._links.self.href + "/incis")
              .then((response) => {
                let inci = response.data._embedded.inci.find(element => element.inciName == ingredient[i].inciName)
                this.postInciConditional(ingredient, i, inci._links.self.href)
              })
          }
          //if it's the SAME regulation AND conditional --> the INCI, % and warning should not be different
          else if (ingredient[i].regulation == ingredient[i - 1].regulation && ingredient[i].conditional == ingredient[i - 1].conditional) {
            this.warningList.push(ingredient[i].name + ' : found the same condition for the same regulation -> skip this line')
            //it's a new regulation
          } else {
            this.postInci(ingredient, i, newInci)
          }
        }
      }
    },

    async postInci(ingredient, i, newInci) {
      // add the inci
      let response = await axios.post("inci", newInci)
      // search regulation to add it
      let regulationsLink = response.data._links.regulations.href;
      //find regulation ID
      var filter = "/search/findByNameOrCountry";
      var paramValue = {
        "searchValue": ingredient[i].regulation,
      };
      axios
        .get("regulation" + filter, {
          params: paramValue
        })
        .then((response) => {
          //put the regulation
          if (response.data._embedded.entityModels.length > 0) {
            let regulation = response.data._embedded.entityModels.find(regulation => regulation.name == ingredient[i].regulation)
            if (regulation != undefined) {
              axios.put(regulationsLink, response.data._embedded.entityModels[0]._links.self.href, {
                headers: {
                  "Content-type": "text/uri-list"
                }
              })
            } else {
              this.errorRegulation.push(ingredient[0].name + ': regulation ' + ingredient[i].regulation + ' not found. Please check manualy')
            }

          } else {
            this.errorRegulation.push(ingredient[0].name + ': regulation ' + ingredient[i].regulation + ' not found. Please check manualy')
          }
        })

      let inciLink = response.data._links.inci.href

      this.postInciConditional(ingredient, i, inciLink)

    },

    postInciConditional(ingredient, i, inciLink) {
      //save conditional
      let conditionalObject = {
        conditional: ingredient[i].conditional,
        percentage: ingredient[i].percentage,
        warning: ingredient[i].warning,
        inci: inciLink
      }
      axios.post("conditional", conditionalObject)
        .then(() => {

        })
        .catch((error) => {
          console.log(error.response);
        })
    },

    async searchMaterial(data) {

      for (let i = 0; i < data.length; i++) {

        let familyIndex = i;
        //create the material
        let material = [data[i]]
        let j = i + 1
        //add all line with the same ingredient name to the array
        if (i < data.length - 1) {
          while (data[i].tradeName == data[j].tradeName) {
            material.push(data[j])
            j++
            i++
            if (j == data.length) {
              break
            }
          }
        }

        material[0].familiesNames = [data[familyIndex].familyOne, data[familyIndex].familyTwo, data[familyIndex].familyThree].filter(f => f)
        material[0].familyOne = null
        material[0].familyTwo = null
        material[0].familyThree = null

        this.findBySupplierDistributorMaterialName(material)
        await sleepNow(SLEEP_MILLISEC)
      }

      this.loading = false
    },

    async findBySupplierDistributorMaterialName(data) {
      //check of supplier exist and get link
      var filter = "/search/findBySupplierNameContainingIgnoreCaseOrderBySupplierName";
      var paramValue = {
        "supplierName": data[0].supplier,
      };
      let supplier = await axios.get("supplier" + filter, {
        params: paramValue
      })

      if (supplier.data._embedded.supplier.length > 0) {
        if (supplier.data._embedded.supplier[0].supplierName.toLowerCase() == data[0].supplier.toLowerCase()) {
          //replace the supplier name by the id
          data[0].supplier = supplier.data._embedded.supplier[0]._links.self.href

          //search Distributor and get link
          filter = "/search/findByDistributorNameContainingIgnoreCaseOrderByDistributorName";
          paramValue = {
            "distributorName": data[0].distributor,
          };
          let distributor = await axios.get("distributor" + filter, {
            params: paramValue
          })

          if (distributor.data._embedded.distributor.length > 0) {
            if (distributor.data._embedded.distributor[0].distributorName.toLowerCase() == data[0].distributor.toLowerCase()) {
              // delete space between numbers and replace ',' with '.' in decimal numbers
              data.map(function(item) {
                if (!item.price) {
                  item.price = '0.00'
                }
                item.price = item.price.replace(/\s/g, '').replace(',', '.')
                return item
              })
              //replace the distributor name by the id
              data[0].distributor = distributor.data._embedded.distributor[0]._links.self.href

              // search for material
              filter = "/search/findByTradeNameContainingIgnoreCaseOrderByTradeName";
              paramValue = {
                "tradeName": data[0].tradeName,
              };
              let response = await axios.get("material" + filter, {
                  params: paramValue
                })

              this.postFamily(data).then(() => {
                if (response.data._embedded.material.length > 0) {
                  if (response.data._embedded.material[0].tradeName.toLowerCase() == data[0].tradeName.toLowerCase()) {
                    let materialId = response.data._embedded.material[0]._links.self.href
                    this.putMaterial(data, materialId)
                  } else {
                    this.postMaterial(data)
                  }
                } else {
                  this.postMaterial(data)
                }
              })

            } else {
              this.errorMaterial.push(data[0].tradeName + ': distributor ' + data[0].distributor + ' not found. Please check manualy')
            }
          } else {
            this.errorMaterial.push(data[0].tradeName + ': distributor ' + data[0].distributor + ' not found. Please check manualy')
          }
        } else {
          this.errorMaterial.push(data[0].tradeName + ': supplier ' + data[0].supplier + ' not found. Please check manualy')
        }
      } else {
        this.errorMaterial.push(data[0].tradeName + ': supplier ' + data[0].supplier + ' not found. Please check manualy')
      }

    },

    async postFamily(data) {
      if (data[0].familiesNames.length > 0) {
        let familiesNames = data[0].familiesNames

        for (let i = 0; i < familiesNames.length; i++) {
          let family = {
            name: familiesNames[i]
          }

          let response = await axios.post("family", family)
          if (response.data.isNew) {
            this.newFamilies.push({
              'material': data[0].tradeName,
              'family': response.data.name
            })
          }
          sleepNow(SLEEP_MILLISEC)
        }
      }
    },

    displayNewFamilies(families) {
      let names = families.map(family => family.family)
      return names.join(', ')
    },

    async postMaterial(data) {
      let prefix = data[0].prefix
      data[0].prefix = null

      let material = await axios.post("material?strPrefix="+prefix, data[0])
        .catch((error) => {
          this.errorMaterial.push(data[0].tradeName + ': not uploaded. Please check manualy')
          console.log(error);
        })

      let materialId = material.data._links.self.href;

      let materialDistributorData = {
        material: materialId,
        distributor: data[0].distributor,
        price: data[0].price,
      }

      await axios.post('materialDistributor', materialDistributorData)
        .catch((error) => {
          console.log(error);
        })

      //link Ingredient
      this.postMaterialIngredient(data, materialId)
    },

    async putMaterial(data, materialId) {
      let prefix = data[0].prefix
      data[0].prefix = null

      //put basic data (supplier is include in data)
      let material = await axios.put(materialId+"?strPrefix="+prefix, data[0]);

      // // let materialIngredientLink = response.data._links.materialIngredients.href;
      let materialDistributorsLink = material.data._links.materialDistributors.href;

      // get the distributor information and update the data
      let materialDistributors = await axios.get(materialDistributorsLink)

      if (materialDistributors.data._embedded.materialDistributor.length > 0) {
        let materialDistributorLink = materialDistributors.data._embedded.materialDistributor[0]._links.self.href;

        let distributorData = await axios.get(materialDistributorLink + '/distributor')
        let distributorServerLink = distributorData.data._links.self.href;

        let materialDistributorData = {
          material: materialId,
          distributor: data[0].distributor,
          price: data[0].price,
        }

        if (distributorServerLink == this.selectedDistributorId) {
          axios.put(materialDistributorLink, materialDistributorData)
        } else {
          await axios.delete(materialDistributorLink)
          axios.post('materialDistributor', materialDistributorData)
        }
      }

      //link Ingredient
      //delete all old ingredient
      let oldIngredients = await axios.get(materialId + "/materialIngredients")
      for (let i = 0; i < oldIngredients.data._embedded.materialIngredient.length; i++) {
        await axios.delete(oldIngredients.data._embedded.materialIngredient[i]._links.self.href)
      }

      this.postMaterialIngredient(data, materialId)

      this.updateMaterial.push(data[0].tradeName + " updated")

    },

    async postMaterialIngredient(data, materialId) {
      for (let i = 0; i < data.length; i++) {
        //search ingredient link
        var searchValue = data[i].ingredient ?? data[i].INCI ?? data[i].casNumber
        var filter = "/search/findByNameOrCasNumberOrInciName";
        var paramValue = {
          "searchValue": searchValue,
        };
        let ingredient = await axios.get("ingredient" + filter, {
          params: paramValue
        })

        if (ingredient.data._embedded.entityModels.length > 0) {
          if (ingredient.data._embedded.entityModels[0].name.toLowerCase() == searchValue.toLowerCase()) {
            let compositionData = {
              percentage: data[i].percentage,
              ingredient: ingredient.data._embedded.entityModels[0]._links.self.href,
              material: materialId
            };

            axios
              .post('materialIngredient', compositionData)
              .then(() => {
                this.importedCounter += 1
              })
              .catch((error) => {
                console.log(error);
              })
          } else {
            this.errorMaterial.push(data[0].tradeName + ': ingredient ' + searchValue + ' not found. Please check manualy')
          }
        } else {
          this.errorMaterial.push(data[0].tradeName + ': ingredient ' + searchValue + ' not found. Please check manualy')
        }
      }
    },

  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.green {
  color: #518078
}
.warn-families {
  color: #3e615b;
  font-weight: bold;
}
</style>
