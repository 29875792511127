import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";

import i18n from "./i18n";

import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faSortUp,
  faSortDown,
  faSort,
  faEdit,
  faEye,
  faTrashAlt,
  faPlusCircle,
  faMinusCircle,
  faInfoCircle,
  faArrowCircleRight,
  faSearch,
  faTimes,
  faChevronLeft,
  faChevronDown,
  faFileExport,
  faPrint,
  faFilePdf,
  faExclamationTriangle,
  faCheck,
  faFileImport,
  faAdjust,
  faClone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Vuex from "vuex";
import store from "./store/store";

//axios config
axios.defaults.baseURL = location.origin + "/api/";
axios.defaults.withCredentials = true;
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.interceptors.response.use(undefined, (error) => {
  if (error.response.status === 401 && router.history.current.path != "/") {
    axios.defaults.withCredentials = false;
    router.push("/");
  }
  return Promise.reject(error);
});

library.add(
  faSortUp,
  faSortDown,
  faSort,
  faEye,
  faEdit,
  faTrashAlt,
  faPlusCircle,
  faMinusCircle,
  faInfoCircle,
  faArrowCircleRight,
  faSearch,
  faTimes,
  faChevronLeft,
  faChevronDown,
  faFileExport,
  faPrint,
  faFilePdf,
  faExclamationTriangle,
  faCheck,
  faFileImport,
  faAdjust,
  faClone
);

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Vuex);
Vue.use(require("vue-moment"));

Vue.directive("can", {
  bind: function (el, binding, vnode) {
    var expression = binding.value.replace(/[']/g, "").replaceAll(" ", "");
    var arrayOfVCanDirective = expression.split("||");

    let control = store.getters.getPermission.userDetails.authorities.some(
      (item) => arrayOfVCanDirective.includes(item.role)
    );

    if (!control) {
      if (binding.arg == "input") {
        vnode.elm.disabled = true;
      } else if (binding.arg == "datepicker") {
        vnode.elm.setAttribute(":disabled", true);
        vnode.elm.setAttribute("aria-disabled", true);
      } else if (binding.arg == "multiselect") {
        vnode.elm.classList.add("multiselect--disabled");
      } else if (binding.arg == "file") {
        vnode.elm
          .getElementsByTagName("input")[0]
          .setAttribute("disabled", true);
      } else {
        vnode.elm.style.display = "none";
      }
    }
  },
});

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("v-select", vSelect);

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
