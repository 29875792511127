<template>
<div v-can="'Read_Ingredient'">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <h2 class="text-left" v-if="$route.name == 'Ingredient'"><router-link to="/ingredients">{{$tc('general.ingredient', 1)}}</router-link></h2>
                <LayoutCard :title="$t('general.informations')" active="true">

                    <template v-slot:header-options>
                        <DeleteButton v-can="'Delete_Ingredient'" endpoint="ingredient" :dataToDisplay="ingredientObject.name" searchValue="null" :relationship="$store.state.ingredient.associatedData" :toDeleteWithRegister="$store.state.ingredient.endpointToDelete">
                        </DeleteButton>
                    </template>

                    <div class="loader" v-if="loading">
                    </div>
                    <fieldset :disabled="loading">
                        <form @change="save()">
                            <div class="form-row">
                                <div class="form-group col-md-12">
                                    <label for="inputName">{{$t('general.name')}}</label>

                                    <b-form-input v-can:input="'Update_Ingredient'" type="text" class="form-control" id="inputName" :state="validateState($v.ingredientObject.name)" :placeholder="$t('ingredient.ingredientName')" @input="getIngredientName(ingredientObject.name)"
                                        :trim="true" v-model="$v.ingredientObject.name.$model" />
                                    <div class="invalid-feedback" id="name-required" v-if="!$v.ingredientObject.name.required">{{$t('errorMsg.required')}}</div>
                                    <div class="invalid-feedback" id="name-minLenght" v-if="!$v.ingredientObject.name.minLength">Name must have at least {{$v.ingredientObject.name.$params.minLength.min}} letters.</div>
                                    <div class="invalid-feedback" v-if="!$v.ingredientObject.name.isIngredientNameUnique">This ingredient name already exist</div>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <label for="inputCas">{{$t('ingredient.casNumber')}}</label>
                                    <input v-can:input="'Update_Ingredient'" type="text" class="form-control" id="inputCas" :placeholder="$t('ingredient.casNumber')" v-model="ingredientObject.casNumber">
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="inputCas">{{$t('ingredient.einecsNumber')}}</label>
                                    <input v-can:input="'Update_Ingredient'" type="text" class="form-control" id="inputEinecs" :placeholder="$t('ingredient.einecsNumber')" v-model="ingredientObject.einecsNumber">
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="form-group col-md-12">
                                    <label for="inputSynonym">{{$t('ingredient.synonym')}}</label>
                                    <textarea v-can:input="'Update_Ingredient'" row="2" maxlength="900" class="form-control" id="inputSynonym" :placeholder="$t('ingredient.synonym')" v-model="ingredientObject.synonym"></textarea>
                                </div>
                            </div>

                        </form>
                    </fieldset>
                </LayoutCard>

                <LayoutCard v-if="this.$route.params.id" :title="$tc('general.regulation', 2)" active="true">
                    <div class="loader" v-if="loading">
                    </div>
                    <fieldset :disabled="loading">
                        <form>
                            <div class="table-responsive table-striped table-hover">
                                <table class="table">
                                    <tr>
                                        <th style="width: 5%">#</th>
                                        <th style="width: 30%">{{$tc('general.regulation', 1)}}</th>
                                        <th style="width: 35%">{{$t('ingredient.inciName')}}</th>
                                        <th style="width: 25%">{{$t('general.note')}}</th>
                                        <th style="width: 5%"></th>
                                    </tr>
                                    <tr v-for="(incis, index) in incisFinalObject" :key="index">
                                        <td>
                                            {{ index + 1 }}
                                        </td>
                                        <td>
                                            <multiselect v-can:multiselect="'Update_Ingredient'" @input="saveInci(index)" v-model="regulationSelected[index]" :options="regulationItems" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true"
                                                placeholder="Type the regulation name" label="name" track-by="url" :preselect-first="false" :taggable="true" @tag="true">
                                            </multiselect>
                                            <div v-if="regulationSelected[index].length == 0" class="error">{{$t('general.selectRegulation')}}</div>
                                        </td>
                                        <td>
                                            <b-form-input v-can:input="'Update_Ingredient'" :id="'input-inci-'+index" list="inci-auxiliary" autocomplete="off" @input="getInciAuxiliary(incis)" :trim="true" @blur="saveInci(index)"
                                                v-model="incis.inciName"></b-form-input>

                                            <datalist id="inci-auxiliary">
                                                <option v-for="inci in inciAuxiliary" :key="inci.inciName" v-bind:value="inci.inciName" v-bind:label="inci.inciName"></option>
                                            </datalist>
                                        </td>
                                        <td>
                                            <input v-can:input="'Update_Ingredient'" class="form-control" v-model="incis.obs" @blur="saveInci(index)" />
                                        </td>
                                        <td>
                                            <b-button variant="outline-primary my-1 my-sm-1 btn-sm" type="button" @click="showModal('conditions');findConditionalsFromInci(incis.url)">Conditions</b-button>
                                        </td>
                                        <td>
                                            <font-awesome-icon v-can="'Update_Ingredient'" class="btn-icon" id="delete-ingredient-button" @click="deleteRow(index)" icon="trash-alt" />
                                        </td>
                                    </tr>
                                    <tr v-show="incisFinalObject.length==0">
                                        <td colspan="5" style="text-align:center;height:100px;vertical-align: middle;">{{$t('general.noRecord')}}</td>
                                    </tr>
                                </table>
                            </div>
                            <div class="d-flex flex-row-reverse mb-4" >
                                <font-awesome-icon v-can="'Update_Ingredient'" id="plus-ingredient-button" icon="plus-circle" class="clickable" @click="addNewRow" />
                            </div>
                        </form>
                    </fieldset>
                </LayoutCard>

                <div v-if="isMod && !this.$route.params.id">
                    <SaveCancelDeleteButtons permission="Create_Ingredient" @clicked="getClicked" :isError="$v.$anyError"></SaveCancelDeleteButtons>
                </div>

                <div class="fixed-top mt-3 d-flex justify-content-center mt2-alerts">
                    <b-alert :show="dismissCountDown" dismissible fade :variant="alertType" @dismiss-count-down="countDownChanged">
                        {{ feedbackMessage }}
                    </b-alert>
                </div>

                <LayoutCard v-if="!loading && this.$route.params.id" :title="$tc('general.material', 2)">
                    <IngredientMaterial :ingredientId="this.$route.params.id"></IngredientMaterial>
                </LayoutCard>

                <LayoutCard v-if="!loading && this.$route.params.id" :title="$t('general.history')">
                    <History :dataType="'ingredient'" :addBy="ingredientObject.addBy" :addDate="ingredientObject.addDate" :lastModBy="ingredientObject.modBy" :lastMod="ingredientObject.modDate" />
                </LayoutCard>

            </div>
        </div>
    </div>

    <ModalWindow v-show="openedModal === 'conditions'" :title="conditionalTitle" @close="closeConditionsModal()">
        <div class="overflow-auto">
            <b-table hover :items="conditionalItems" :fields="fields">
                <template v-slot:cell()="{ item, field: { key } }">
                    <b-form-input v-can:input="'Update_Ingredient'" v-model="item[key]" @blur.native="saveConditional(item)" />
                </template>
                <template #cell(percentage)="{ item, field: { key } }">
                    <b-form-input v-can:input="'Update_Ingredient'" type="number" value="100" min="0" max="100" step="0.01" v-model="item[key]" @blur.native="saveConditional(item)" />
                </template>
                <template #head(actions)>
                    <span v-can="'Update_Ingredient'">{{$t('general.remove')}}</span>
                </template>
                <template #cell(actions)="row">
                    <font-awesome-icon  v-can="'Update_Ingredient'" class="btn-icon" id="delete-ingredient-button" @click="removeConditional(row)" icon="trash-alt" />
                </template>
            </b-table>
            
            <div v-show="conditionalValidated==false" class="text-center validateError">
                {{$t('conformity.conditionalValidation')}}
            </div>
            <div class="text-right">
                <font-awesome-icon v-can="'Update_Ingredient'" id="plus-ingredient-button" icon="plus-circle" class="clickable" @click="createNewCondition" />
            </div>
        </div>
    </ModalWindow>

</div>
</template>

<script>
import axios from 'axios';
import History from '../components/History.vue';
import LayoutCard from '../components/LayoutCard.vue';
import IngredientMaterial from '../components/IngredientMaterial.vue';
import Multiselect from 'vue-multiselect';
import ModalWindow from '../components/ModalWindow.vue';
import SaveCancelDeleteButtons from '../components/SaveCancelDeleteButtons.vue';
import DeleteButton from '../components/DeleteButton.vue';
import Feedback from '../mixins/Feedback.js';
import CommonVariablesAndFunctions from '../mixins/CommonVariablesAndFunctions.js';
import {
    required,
    minLength,
} from 'vuelidate/lib/validators'

function isIngredientNameUnique(value) {
    if (this.ingredientsSaved.filter(e => e.name.toLowerCase() === value.toLowerCase()).length == 0) {
        return true
    }
    return false
}

window.axios = require("axios");

export default {
    name: 'Ingredient',
    mixins: [Feedback, CommonVariablesAndFunctions],
    components: {
        History,
        LayoutCard,
        Multiselect,
        DeleteButton,
        SaveCancelDeleteButtons,
        ModalWindow,
        IngredientMaterial
    },
    props: {
        ingredientId: {
            required: false
        }
    },
    data() {
        return {
            title: "Ingredient",
            endpoint: 'ingredient',
            conditionalValidated: true,

            fields: [{
                    key: 'conditional'
                },
                {
                    key: 'percentage'
                },
                {
                    key: 'warning'
                },
                {
                    key: 'actions',
                    label: 'Remove'
                }
            ],

            conditionalItems: [],

            statusItems: [],

            inciAuxiliary: [],

            conditionalTitle: null,

            openedModal: null,

            inciIndex: null,

            ingredientObject: {
                addBy: "",
                addDate: "",
                modBy: "",
                modDate: null,
                name: "",
                casNumber: "",
                einecsNumber: "",
                synonym: "",
                materialIngredient: [],
                ingredientItemOfFormulation: [],
            },
            inciObject: [],
            incisFinalObject: [],
            regulationItems: [],
            regulationSelected: [],
            responseConditionalsFromInci: null,
            inciOpened: null,
            ingredientsSaved: []
        };
    },
    methods: {
        showModal(type) {
            this.openedModal = type
        },

        closeConditionsModal(){
            this.validateConditionsModal()
            if(this.conditionalValidated) this.openedModal = null
        },

        validateConditionsModal(){
            this.conditionalValidated = true;
            this.conditionalItems.forEach(element => {
                if(element.percentage==null||element.percentage==""){
                    this.conditionalValidated = false;
                    return;
                }
            });
        },

        removeConditional(row) {
            this.conditionalValidated = true
            var url = row.item._links.self.href;
            axios.delete(url)
                .then(() => {
                    this.findConditionalsFromInci(this.inciOpened);
                })
                .catch((error) => {
                    this.errorAlert(error)
                })
        },

        async createNewCondition() {
            this.conditionalValidated = true
            let conditionalObject = {
                conditional: "",
                percentage: 0,
                warning: "",
                inci: this.inciOpened
            }

            //save new conditional
            await axios.post("conditional", conditionalObject);

            this.findConditionalsFromInci(this.inciOpened);
        },

        async saveConditional(value) {
            if(!value.percentage){
                return;
            }
            
            let conditionalObject = {
                conditional: value.conditional,
                percentage: value.percentage,
                warning: value.warning
            }

            //save conditional
            await axios.put(value._links.conditional.href, conditionalObject)
                .then(() => {
                    this.succesAlert();
                })
                .catch((error) => {
                    this.errorAlert(error)
                })
        },

        async findConditionalsFromInci(url) {
            var strArray = url.split("/");
            var inciId = strArray[strArray.length - 1];
            let responseConditionalsFromInci = await axios.get("/conditional/search/findByInciIdOrderByAddDateDesc?id=" + inciId);
            this.inciOpened = url;
            this.conditionalItems = responseConditionalsFromInci.data._embedded.conditional;
            this.conditionalTitle = "INCI Conditions for Regulation ";
        },

        async loadOptionFields() {

            //get status list
            let responseStatusMaterialINCIRegulation = await axios.get('statusMaterialINCIRegulation');
            this.statusItems = responseStatusMaterialINCIRegulation.data.content;

            let responseInciAuxiliary = await axios.get('inciAuxiliary');
            this.inciAuxiliary = responseInciAuxiliary.data._embedded.inciAuxiliary;

            //get regulation list
            let responseRegulation = await axios.get('regulation');
            var dataLengthRegulation = responseRegulation.data.page.totalElements
            for (let i = 0; i < dataLengthRegulation; i++) {

                this.regulationItems.push({
                    url: responseRegulation.data._embedded.regulation[i]._links.self.href,
                    name: responseRegulation.data._embedded.regulation[i].name
                })
            }
        },

        async getInciAuxiliary(incis) {

            if (incis.inciName.length > 1) {
                var filter = "/search/findByInciNameContainingIgnoreCase";

                var paramValue = {
                    "inciName": incis.inciName,
                    "size": 10
                };

                let responseInciAuxiliary = await axios.get("inciAuxiliary" + filter, {
                    params: paramValue
                })

                this.inciAuxiliary = responseInciAuxiliary.data._embedded.inciAuxiliary;
            }

        },

        async saveInci(_index) {

            //if it is posting new register needs to ignore this function
            if (!this.$route.params.id) {
                return;
            }

            if (this.regulationSelected[_index].length == 0){
                return;
            }

            //organize data
            let selectedObjectInstance = {};
            let regulationTempString = "";
            let inciUrl = this.incisFinalObject[_index].url;
            let inciRegulationsUrl = inciUrl + "/regulations";
            selectedObjectInstance.inciName = this.incisFinalObject[_index].inciName;
            selectedObjectInstance.obs = this.incisFinalObject[_index].obs;
            selectedObjectInstance.ingredient = this.endpoint + '/' + this.$route.params.id;

            //save inciName and notes from inci
            await axios.put(inciUrl, selectedObjectInstance)
                .then(() => {
                    this.succesAlert();
                })
                .catch((error) => {
                    this.errorAlert(error)
                })

            //put each regulation related to inci
            for (var j = 0; j < this.regulationSelected[_index].length; j++) {
                regulationTempString += this.regulationSelected[_index][j].url + "\n";
            }

            //if removed all regulations then clean the relationship - if not just put overwriting
            if (regulationTempString == "") {
                let lastRegulationInci = await axios.get(inciRegulationsUrl);
                let idRegulation = "/" + lastRegulationInci.data._embedded.regulation[0]._links.regulation.href.split("/").pop();
                await axios.delete(inciRegulationsUrl + idRegulation, {
                    headers: {
                        "X-HTTP-Method-Override": "DELETE"
                    }
                });
            } else {
                await axios.put(inciRegulationsUrl, regulationTempString, {
                    headers: {
                        "Content-type": "text/uri-list"
                    }
                })
            }
        },

        async addNewRow() {

            let newTableRow = {
                regulations: [],
                inciName: "New INCI",
                obs: "",
                addBy: "",
                addDate: "",
                modBy: "",
                modDate: "",
                url: ""
            };
            this.incisFinalObject.push(newTableRow);
            this.regulationSelected.push([])

            //post new object to api and create a new table row
            if (this.$route.params.id) {
                try {

                    //prepare data to post new row to api
                    let newObjectInstance = {};
                    newObjectInstance.inciName = "New INCI";
                    newObjectInstance.obs = "";
                    newObjectInstance.ingredient = this.endpoint + '/' + this.$route.params.id;

                    let response = await axios.post("inci", newObjectInstance);
                    this.incisFinalObject[this.incisFinalObject.length - 1].url = response.data._links.self.href;
                } catch (error) {
                    console.log(error.response)
                    this.errorAlert(error)
                }
            }
        },

        deleteRow(_index) {
            //delete row at table and post delete method to api
            if (this.$route.params.id) {
                let urlDelete = "inci/" + this.incisFinalObject[_index].url.split("/").pop();
                axios.delete(urlDelete)
                    .then(() => {
                        this.incisFinalObject.splice(_index, 1);
                        this.regulationSelected.splice(_index, 1);
                        this.succesAlert();
                    })
                    .catch((error) => {
                        console.log(error.response)
                        this.errorAlert(error)
                    })

                //if it is posting new just remove the table row
            } else {
                this.incisFinalObject.splice(_index, 1);
            }

        },

        async getData() {

            this.loading = true;

            //get the ingredient object
            let response = await axios.get(this.endpoint + '/' + this.$route.params.id);
            this.ingredientObject = response.data;

            //get the inci object related to ingredient
            let responseInci = await axios.get(this.ingredientObject._links.incis.href);
            this.inciObject = responseInci.data._embedded.inci;

            //for each inciObject needs to find for /regulation
            for (var inciInstance in this.inciObject) {

                let responseRegulationInci = await axios.get(this.inciObject[inciInstance]._links.regulations.href);
                let incisFinalInstance = [];
                let inciRegulationArray = [];

                //for each responseRegulationInci needs to get details of /regulation
                for (var i = 0; i < responseRegulationInci.data._embedded.regulation.length; i++) {

                    //set inciRegulationArray
                    inciRegulationArray.push({
                        url: responseRegulationInci.data._embedded.regulation[i]._links.self.href,
                        name: responseRegulationInci.data._embedded.regulation[i].name
                    });
                }

                //set incisFinalObject
                incisFinalInstance.inciName = this.inciObject[inciInstance].inciName;
                incisFinalInstance.obs = this.inciObject[inciInstance].obs;
                incisFinalInstance.addBy = this.inciObject[inciInstance].addBy;
                incisFinalInstance.addDate = this.inciObject[inciInstance].addDate;
                incisFinalInstance.modBy = this.inciObject[inciInstance].modBy;
                incisFinalInstance.modDate = this.inciObject[inciInstance].modDate;
                incisFinalInstance.url = this.inciObject[inciInstance]._links.self.href;
                this.incisFinalObject.push(incisFinalInstance);
                this.regulationSelected.push(inciRegulationArray);
            }

            //finishes loading
            this.loading = false;
            this.loaded = true;
            this.isMod = false;
        },
        processForm() {
            let general = {
                name: this.ingredientObject.name.charAt(0).toUpperCase() + this.ingredientObject.name.slice(1),
                casNumber: this.ingredientObject.casNumber,
                einecsNumber: this.ingredientObject.einecsNumber,
                synonym: this.ingredientObject.synonym
            };

            return general;
        },

        async postData(endpoint, informations) {

            let inci = this.incisFinalObject;

            try {

                //posts the ingredient main entity
                let postResponse = await axios.post("ingredient", informations);
                let ingredientURL = postResponse.data._links.self.href;
                let id = postResponse.data._links.self.href.split("/").pop();

                //posts each inci
                for (var i = 0; i < inci.length; i++) {
                    let inciFinal = {};
                    var regulationTempString = "";
                    inciFinal.inciName = inci[i].inciName;
                    inciFinal.obs = inci[i].obs;
                    inciFinal.ingredient = ingredientURL;

                    let postInciResponse = await axios.post("inci", inciFinal);

                    //put each regulation related to inci
                    for (var j = 0; j < this.regulationSelected[i].length; j++) {
                        regulationTempString += this.regulationSelected[i][j].url + "\n";
                    }

                    await axios.put(postInciResponse.data._links.regulations.href, regulationTempString, {
                        headers: {
                            "Content-type": "text/uri-list"
                        }
                    })
                }

                //finishes the POST operation
                this.succesAlert();

                this.isMod = false;
                this.$router.push(this.endpoint + '/' + id);
            } catch (error) {
                console.log(error)
                this.errorAlert(error);
            }
        },
        async putData(endpoint, informations) {

            try { //put basic data
                await axios.put(endpoint, informations);
                this.succesAlert();

            } catch (error) {
                this.errorAlert(error);
            }

        },

        async getIngredientName(input) {
            if (this.ingredientObject.name.length > 1) {
                var filter = "/search/findByNameContainingIgnoreCaseOrderByName";
                var paramValue = {
                    "name": input,
                };
                let response = await axios.get("ingredient" + filter, {
                    params: paramValue
                })
                this.ingredientsSaved = response.data._embedded.ingredient;
            }
        }
    },
    created() {
        this.loadOptionFields();

        if (this._props.ingredientId != null){
            this.$route.params.id = this._props.ingredientId
        }
        if (this.$route.params.id != undefined) {
            this.getData();
        } else {
            this.loading = false;
            this.loaded = true;
        }
    },

    validations: {
        ingredientObject: {
            name: {
                required,
                minLength: minLength(1),
                isIngredientNameUnique
            },
        }
    }

};
</script>

<style scoped>
.form-group {
    text-align: left;
    font-weight: bold;
}

.mt2-alerts {
    position: fixed;
    z-index: 9999;
}

.clickable {
    cursor: pointer;
}

.validateError {
    color: red;
    font-size: 0.8rem;
}

.table-responsive {
    overflow-x: visible !important;
    overflow-y: visible !important;
}
</style>
