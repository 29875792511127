<template>
<div v-can="'Read_Contact'">

    <div class="container-fluid">
        <div class="row">

            <div class="col-md-12">
                <h2 class="text-left"><router-link to="/contacts">{{$tc('general.contact', 1)}}</router-link></h2>

                <LayoutCard :title="$t('general.informations')" active="true">

                    <template v-slot:header-options>
                        <DeleteButton v-can="'Delete_Contact'" endpoint="contact" :dataToDisplay="objectData.firstName + ' ' + objectData.lastName" :searchValue="objectData.lastName" :relationship="$store.state.contact.associatedData"></DeleteButton>
                    </template>

                    <div class="loader" v-if="loading">
                    </div>

                    <fieldset :disabled="loading">
                        <form @change="save()">

                            <div class="form-row">
                                <div class="form-group col-md-5">
                                    <label for="inputFirstName">{{ $t('contact.firstname')}}</label>
                                    <b-form-input v-can:input="'Update_Contact'" type="text" class="form-control" :state="validateState($v.objectData.firstName)" id="inputFirstName" placeholder="First name" :lazy="true" :trim="true" v-model="$v.objectData.firstName.$model" />
                                    <div class="invalid-feedback" id="name-required" v-if="!$v.objectData.firstName.required">{{$t('errorMsg.required')}}</div>
                                    <div class="invalid-feedback" id="name-minLenght" v-if="!$v.objectData.firstName.minLength">Name must have at least {{$v.objectData.firstName.$params.minLength.min}} letters.</div>
                                </div>
                                <div class="form-group col-md-5">
                                    <label for="inputLastName">{{$t('contact.lastName')}}</label>
                                    <input v-can:input="'Update_Contact'" type="text" class="form-control" id="inputLastName" :placeholder="$t('contact.lastName')" required v-model="objectData.lastName">
                                </div>


                                <div class="form-group col-md-2">
                                    <label>{{$t('general.status')}}</label>
                                    <v-select id="inputContactStatus" placeholder="Select a status" v-model="$v.objectData.status.$model" :reduce="status => status.id" :selectable="status => status.selectable" :clearable="false" :options="contactStatus" label="name" @input="save" :class="validateStatus($v.objectData.status)"
                                        class="vselect">
                                    </v-select>
                                    <div class="error" id="status-required" v-if="!$v.objectData.status.required && $v.objectData.status.$dirty">{{$t('errorMsg.required')}}</div>
                                </div>

                            </div>

                            <div class="form-group">
                                <label for="inputRole">{{$t('contact.function')}}</label>
                                <b-form-input v-can:input="'Update_Contact'" type="text" class="form-control" id="inputRole" :placeholder="$t('contact.function')" :trim="true" v-model="objectData.role" />
                            </div>

                            <div class="form-group">
                                <label for="inputEmail">{{$t('contact.email')}}</label>
                                <b-form-input v-can:input="'Update_Contact'" type="text" class="form-control" id="inputEmail" :placeholder="$t('contact.email')" :state="validateState($v.objectData.email)" :lazy="true" :trim="true" v-model="$v.objectData.email.$model" />
                                <div class="invalid-feedback" id="email-valid" v-if="!$v.objectData.email.email">enter a valid email adress</div>
                            </div>

                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <label for="inputPhone">{{$t('contact.phoneNumber')}}</label>
                                    <input v-can:input="'Update_Contact'" type="text" class="form-control" id="inputPhone" :placeholder="$t('contact.phoneNumber')" v-model="objectData.phoneNumber">
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="inputFax">{{$t('contact.faxNumber')}}</label>
                                    <input v-can:input="'Update_Contact'" type="text" class="form-control" id="inputFax" :placeholder="$t('contact.faxNumber')" v-model="objectData.faxNumber">
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="inputAdress">{{$t('contact.adress')}}</label>
                                <input v-can:input="'Update_Contact'" type="text" class="form-control" id="inputAdress" placeholder="1234 Main street" v-model="objectData.address">
                            </div>

                            <div class="form-row">
                                <div class="form-group col-md-4">
                                    <label for="inputCity">{{$t('contact.city')}}</label>
                                    <input v-can:input="'Update_Contact'" type="text" class="form-control" id="inputCity" :placeholder="$t('contact.city')" v-model="objectData.city">
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="inputState">{{$t('contact.state')}}</label>
                                    <input v-can:input="'Update_Contact'" type="text" class="form-control" id="inputState" :placeholder="$t('contact.state')" v-model="objectData.state">
                                </div>
                                <div class="form-group col-md-2">
                                    <label for="inputZip">{{$t('contact.zipCode')}}</label>
                                    <input v-can:input="'Update_Contact'" type="text" class="form-control" id="inputZip" :placeholder="$t('contact.zipCode')" v-model="objectData.zipCode">
                                </div>

                                <div class="form-group col-md-3">
                                    <label for="inputCountry">{{$t('contact.country')}}</label>
                                    <b-form-input v-can:input="'Update_Contact'" id="inputCountry" list="country-list" :placeholder="$t('contact.country')" autocomplete="off" :state="validateCountry()" :lazy="true" :trim="true" v-model="$v.selectedCountryName.$model">
                                    </b-form-input>
                                    <div class="invalid-feedback" id="country-required" v-if="!$v.selectedCountryName.required && $v.selectedCountryName.$dirty">{{$t('errorMsg.required')}}</div>
                                    <div class="invalid-feedback" id="country-must-exist" v-if="!$v.selectedCountryId.countryMustExist && $v.selectedCountryName.$dirty">Country must exist</div>
                                    <datalist id="country-list">
                                        <option v-for="country in countries" :key="country.id" field-value="country.id"> {{ country.name }}</option>
                                    </datalist>

                                </div>

                            </div>

                        </form>
                    </fieldset>

                </LayoutCard>

                <div id="save-button" v-if="isMod && !this.$route.params.id">

                    <SaveCancelDeleteButtons permission="Create_Contact" @clicked="getClicked" :isError="$v.$anyError"></SaveCancelDeleteButtons>

                </div>

                <div class="fixed-top mt-3 d-flex justify-content-center">
                    <b-alert :show="dismissCountDown" dismissible fade :variant="alertType" @dismiss-count-down="countDownChanged">
                        {{ feedbackMessage }}
                    </b-alert>
                </div>


                <LayoutCard v-if="!loading && this.$route.params.id" :title="$t('general.history')">

                    <History :dataType="endpoint" :addBy="objectData.addBy" :addDate="objectData.addDate" :lastModBy="objectData.modBy" :lastMod="objectData.modDate" />

                </LayoutCard>

            </div>
        </div>
    </div>

</div>
</template>

<script>
import axios from 'axios'
import History from '../components/History.vue'
import LayoutCard from '../components/LayoutCard.vue'
import DeleteButton from '../components/DeleteButton.vue'
import SaveCancelDeleteButtons from '../components/SaveCancelDeleteButtons.vue'
import {
    required,
    minLength,
    email
} from 'vuelidate/lib/validators'

import {
    mapActions
} from "vuex";

import Feedback from '../mixins/Feedback.js'
import CommonVariablesAndFunctions from '../mixins/CommonVariablesAndFunctions.js'

window.axios = require("axios");

const countryMustExist = (value) => value != ""


export default {
    name: 'Contact',

    mixins: [Feedback, CommonVariablesAndFunctions],

    components: {
        History,
        SaveCancelDeleteButtons,
        DeleteButton,
        LayoutCard
    },

    props: {
        contactId: {
            required: false
        }
    },

    data() {
        return {
            title: "Contact",
            endpoint: "contact",

            selectedCountryName: "",

            countries: [],

            objectData: {
                addBy: "",
                addDate: "",
                modBy: "",
                modDate: "",
                firstName: "",
                lastName: "",
                role: "",
                phoneNumber: "",
                faxNumber: "",
                email: "",
                address: "",
                city: "",
                zipCode: "",
                state: "",
                country: "",
                status: "",
            },

        };
    },
    computed: {

        selectedCountryId: function() {
            if (this.selectedCountryName != "") {
                if (this.countries.filter(country => country.name == this.selectedCountryName).length != 0) {
                    let id = this.countries.filter(country => country.name == this.selectedCountryName)[0].id
                    return id
                } else {
                    return ""
                }
            }
            return ""
        },

        contactStatus: function() {

            if (this.$store.getters.getStatusFile.contact.length == 0) {
                this.statusFile('contact')
            }

            return this.$store.getters.getStatusFile.contact
        },
    },

    methods: {
        ...mapActions(["statusFile"]),

        validateCountry() {
            if (this.$v.selectedCountryName.$dirty && this.$v.selectedCountryName.$error) {
                return false
            }
            if (this.$v.selectedCountryName.$dirty != "" && this.$v.selectedCountryId.$invalid) {
                return false
            }
            if (this.$v.selectedCountryName.$dirty) {
                return true
            }
            return null
        },

        getData() {
            this.loading = true,
                axios
                .get(this.endpoint + '/' + this.$route.params.id)
                .then((response) => {
                    this.objectData = response.data

                    axios
                        .get('country/' + response.data.country)
                        .then((country) => {
                            this.selectedCountryName = country.data.name

                            this.loading = false;
                            this.loaded = true;
                            this.isMod = false;
                        })
                        .catch((error) => {
                            console.log(error.response);
                        })
                })
                .catch((error) => {
                    console.log(error.response)
                    this.errorAlert(error)
                })
        },

        putData(endpoint, data) {
            axios
                .put(endpoint, data)
                .then(() => {
                    this.succesAlert()
                    // this.getData();
                })
                .catch((error) => {
                    this.errorAlert(error)
                    console.log(error.response)
                })
        },

        postData(endpoint, data) {
            axios
                .post(endpoint, data)
                .then((response) => {
                    this.succesAlert()
                    let id = response.data._links.self.href.split("/").pop();
                    this.$router.push(this.endpoint + '/' + id);
                    this.getData();
                })
                .catch((error) => {
                    console.log(error.response)
                    this.errorAlert(error)
                })
        },

        processForm() {

            let data = {
                firstName: this.objectData.firstName.charAt(0).toUpperCase() + this.objectData.firstName.slice(1).toLowerCase(),
                lastName: this.objectData.lastName.charAt(0).toUpperCase() + this.objectData.lastName.slice(1).toLowerCase(),
                role: this.objectData.role,
                phoneNumber: this.objectData.phoneNumber,
                faxNumber: this.objectData.faxNumber,
                email: this.objectData.email,
                address: this.objectData.address,
                city: this.objectData.city,
                state: this.objectData.state,
                zipCode: this.objectData.zipCode,
                country: this.selectedCountryId,
                status: this.objectData.status,
            };

            return data

        },

    },
    //launch once the view is created
    async created() {

        try {

            let country = await axios.get('country')
            this.countries = country.data._embedded.hashMaps

        } catch (error) {
            this.errorAlert(error);
        }

        if (this._props.contactId != null){
            this.$route.params.id = this._props.contactId
        }
        if (this.$route.params.id != undefined) {
            this.getData();
        } else {
            this.loading = false;
        }
    },

    validations: {
        objectData: {
            firstName: {
                required,
                minLength: minLength(1)
            },
            email: {
                email
            },
            status: {
                required,
            }
        },
        selectedCountryName: {
            required,
        },
        selectedCountryId: {
            countryMustExist
        }
    }
};
</script>

<style scoped>
.form-group {
    text-align: left;
    font-weight: bold;
}
</style>
