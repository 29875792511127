<template>
<div>
    <div class="container-fluid">

        <div class="row mb-4">
            <div class="col-md-4">
                <div class="card border-primary h-100">
                    <div class="card-body mt-2">
                        <div class="d-flex flex-column align-items-center text-center">
                            <img :src="require(`@/assets/avatar/${user.avatar}`)" alt="Admin" class="rounded-circle btn" width="200" @click="showModal('avatar')">
                            <div class="mt-3">
                                <b-form-input v-if="logUserPermission.name == 'administrator'" type="text" placeholder="Username" class="form-control mb-2" style="text-align:center" :state="validateState($v.user.userName)" :trim="true"
                                    v-model="$v.user.userName.$model" />
                                <h4 v-else>{{user.userName}}</h4>
                                <div class="invalid-feedback" id="company-name-required" v-if="!$v.user.userName.required">{{$t('errorMsg.required')}}</div>

                                <b-form-select v-if="logUserPermission.name == 'administrator' && !loading && this.$route.params.id" v-model="userRole._links.self.href" :options="roles" value-field="_links.self.href" text-field="name"
                                    class="form-control mb-2" style='text-align-last:center' @change="save()"></b-form-select>
                                <b-form-select v-if="logUserPermission.name == 'administrator' && !loading && !this.$route.params.id" v-model="$v.userRole.$model" :state="validateState($v.userRole)" :options="roles" value-field="_links.self.href"
                                    text-field="name" class="form-control mb-2" style='text-align-last:center' @change="save()">
                                    <template v-slot:first>
                                        <b-form-select-option :value="''" disabled>{{$t('user.selectRole')}}</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <div class="invalid-feedback" id="status-required" v-if="!$v.userRole.required">{{$t('errorMsg.required')}}</div>
                                <p v-if="logUser.role != 'admin' && !loading && this.$route.params.id" class="text-info mb-1">{{userRole.name}}</p>

                            </div>
                            <div v-if="logUserPermission.name == 'administrator' && !this.$route.params.id">
                                <b-form-input type="password" class="form-control" :state="validateState($v.user.password)" placeholder="password" :trim="true" v-model="$v.user.password.$model" />
                                <div class="invalid-feedback" id="status-required" v-if="!$v.user.password.required">{{$t('errorMsg.required')}}</div>
                                <div class="invalid-feedback" id="status-required" v-if="!$v.user.password.minLength">{{$t('errorMsg.useAtLeast')}} 6 {{$tc('errorMsg.character',2)}}</div>

                                <b-form-input type="password" class="form-control" :state="validateState($v.confirmedPassword)" :placeholder="$t('user.confirmPassword')" :trim="true" v-model="$v.confirmedPassword.$model" />
                                <div class="invalid-feedback" id="status-required" v-if="!$v.confirmedPassword.sameAsPassword">{{$t('user.passwordNotIdentical')}}</div>
                            </div>
                            <div>
                                <b-button v-if="logUser.userName == user.userName || logUserPermission.name == 'administrator' && this.$route.params.id" variant="outline-primary" size="sm" class="mr-auto mt-2" v-b-modal="'password'">
                                    {{$t('user.changePassword')}}
                                </b-button>
                                <b-button v-if="logUser.userName == user.userName || logUserPermission.name == 'administrator'" variant="outline-primary" size="sm" class="mr-auto ml-2 mt-2" v-b-modal="'preferences'">{{$t('user.preferences')}}
                                </b-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-8">
                <div class="card border-primary h-100">
                    <div class="card-body">
                        <form @change="save">
                            <div class="row mt-3 mb-3">
                                <label for="firstname" class="col-sm-3 font-weight-bold text-left" :class="logUser.userName == user.userName ? 'col-form-label':''">{{$t('contact.firstname')}}</label>
                                <div class="col-sm-9">
                                    <b-form-input v-if="logUser.userName == user.userName || logUserPermission.name == 'administrator'" type="text" class="form-control" :state="validateState($v.user.firstName)" :placeholder="$t('contact.firstname')"
                                        :trim="true" v-model="$v.user.firstName.$model" />
                                    <p v-else class="text-left" id="firstname">
                                        {{user.firstName}}
                                    </p>
                                    <div class="invalid-feedback" id="first-name-required" v-if="!$v.user.firstName.required">{{$t('errorMsg.required')}}</div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <label for="lastname" class="col-sm-3 font-weight-bold text-left" :class="logUser.userName == user.userName ? 'col-form-label':''">{{$t('contact.lastName')}}</label>
                                <div class="col-sm-9">
                                    <b-form-input v-if="logUser.userName == user.userName || logUserPermission.name == 'administrator'" type="text" class="form-control" :state="validateState($v.user.lastName)" :placeholder="$t('contact.lastName')"
                                        :trim="true" v-model="$v.user.lastName.$model" />
                                    <p v-else class="text-left" id="lastname">
                                        {{user.lastName}}
                                    </p>
                                    <div class="invalid-feedback" id="last-name-required" v-if="!$v.user.lastName.required">{{$t('errorMsg.required')}}</div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <label for="mail" class="col-sm-3 font-weight-bold text-left" :class="logUser.userName == user.userName ? 'col-form-label':''">{{$t('contact.email')}}</label>
                                <div class="col-sm-9">
                                    <b-form-input v-if="logUser.userName == user.userName || logUserPermission.name == 'administrator'" type="text" class="form-control" :state="validateState($v.user.email)" :placeholder="$t('contact.email')"
                                        :trim="true" v-model="$v.user.email.$model" />
                                    <p v-else class="text-left" id="mail">
                                        {{user.email}}
                                    </p>
                                    <div class="invalid-feedback" id="email-required" v-if="!$v.user.email.required">{{$t('user.validEmail')}}</div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <label for="phone" class="col-sm-3 font-weight-bold text-left" :class="logUser.userName == user.userName ? 'col-form-label':''">{{$t('contact.phoneNumber')}}</label>
                                <div class="col-sm-9">
                                    <input v-if="logUser.userName == user.userName || logUserPermission.name == 'administrator'" type="text" id="phone" class="form-control" v-model="user.phoneNumber" :placeholder="$t('contact.phoneNumber')">
                                    <p v-else class="text-left" id="phone">
                                        {{user.phoneNumber}}
                                    </p>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <label for="adresse" class="col-sm-3 font-weight-bold col-form-label text-left">{{$t('contact.adress')}}</label>
                                <div class="col-sm-9">
                                    <b-form-textarea v-if="logUser.userName == user.userName || logUserPermission.name == 'administrator'" :state="validateState($v.user.address)" :placeholder="$t('contact.adress')" id="address"
                                        v-model="$v.user.address.$model" rows="3" max-rows="6"></b-form-textarea>
                                    <p v-else class="text-left" id="adress">
                                        {{user.address}}
                                    </p>
                                    <div class="invalid-feedback" id="address-max-lenght" v-if="!$v.user.email.maxLenght">{{$t('errorMsg.tooLong')}} (max 500)</div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>



        <div class="row" v-if="this.$route.params.id && logUser.userName == user.userName">
            <div class="col-md-6 mb-3">
                <b-card header-class="card-header-projects" header-text-variant="white" align="center" class="card-projects">
                    <template #header>
                        <h5 class="mb-0">{{$t('dashboard.lastModifiedProjects')}}</h5>
                    </template>
                    <b-card-text>

                        <b-table hover small :items="fetchProjects" :fields="projectsFields">

                            <template #cell(modDate)="row">
                                {{row.value | moment("L")}}
                            </template>

                            <template #cell(targetDate)="row">
                                {{row.value | moment("L")}}
                            </template>

                            <template #cell(status)="row">
                                <PillStatus :status="row.value"></PillStatus>
                            </template>

                            <template #cell(goTo)="row">
                                <font-awesome-icon @click="navigateTo(row.item._links.self.href)" class="btn-icon" icon="arrow-circle-right" />
                            </template>

                        </b-table>

                    </b-card-text>
                </b-card>
            </div>

            <div class="col-md-6 mb-3">
                <b-card header-class="card-header-formulations" header-text-variant="white" align="center" class="card-formulations">
                    <template #header>
                        <h5 class="mb-0">{{$t('dashboard.lastModifiedFormulations')}}</h5>
                    </template>
                    <b-card-text>

                        <b-table hover small :items="fetchFormulations" :fields="formulationsFields">

                            <template #cell(modDate)="row">
                                {{row.value | moment("L")}}
                            </template>

                            <template #cell(targetDate)="row">
                                {{row.value | moment("L")}}
                            </template>

                            <template #cell(status)="row">
                                <PillStatus :status="row.value"></PillStatus>
                            </template>

                            <template #cell(goTo)="row">
                                <font-awesome-icon @click="navigateTo(row.item._links.self.href)" class="btn-icon" icon="arrow-circle-right" />
                            </template>

                        </b-table>

                    </b-card-text>
                </b-card>
            </div>

        </div>

        <div class="fixed-top mt-3 d-flex justify-content-center">
            <b-alert :show="dismissCountDown" dismissible fade :variant="alertType" @dismiss-count-down="countDownChanged">
                {{ feedbackMessage }}
            </b-alert>
        </div>

    </div>

    <div v-if="isMod">
        <SaveCancelDeleteButtons permission="Create_User" @clicked="getClicked" :isError="$v.$anyError"></SaveCancelDeleteButtons>
    </div>

    <b-modal id="password" size="lg" :title="$t('user.changePassword')" hide-footer>

        <ChangePassword :userName="user.userName"></ChangePassword>

    </b-modal>

    <b-modal id="preferences" size="lg" :title="$t('user.preferences')" @ok="savePreferences">

        <div class="row mt-3 mb-5">
            <label for="language" class="col-sm-3 font-weight-bold col-form-label">{{$t('user.language')}}</label>
            <div class="col-sm-9">
                <b-form-select v-model="user.language" :options="languages" value-field="id" text-field="name" class="mb-3"></b-form-select>
            </div>
        </div>

    </b-modal>

    <b-modal ref="avatar" id="avatar" size="xl" :title="$t('user.preferences')" @ok="saveAvatar">

        <div class="row ml-3">
            <div v-for="(avatar, index) in avatars" :key="avatar.pathShort" :class="{'active': isActive == index}" @click="changeSelectedAvatar(avatar.pathShort, index)">
                <img :src="avatar.pathLong" alt="Admin" class="rounded-circle btn" width="100">
            </div>
        </div>

    </b-modal>

</div>
</template>

<script>
import axios from 'axios'
import PillStatus from '../components/PillStatus.vue'
import SaveCancelDeleteButtons from '../components/SaveCancelDeleteButtons.vue'
import ChangePassword from '../components/ChangePassword.vue'
import Feedback from '../mixins/Feedback.js'
import CommonVariablesAndFunctions from '../mixins/CommonVariablesAndFunctions.js'

import {
    required,
    minLength,
    maxLength,
    sameAs,
    email
} from 'vuelidate/lib/validators'

import {
    mapGetters
} from 'vuex'

window.axios = require("axios");

export default {

    name: 'User',

    components: {
        PillStatus,
        ChangePassword,
        SaveCancelDeleteButtons
    },

    mixins: [Feedback, CommonVariablesAndFunctions],

    data() {
        return {
            title: "User",
            endpoint: "user",

            user: {
                userName: "",
                password: "",
                avatar: "unknown.png",
                firstName: "",
                lastName: "",
                email: "",
                phoneNumber: "",
                address: "",
                language: "EN",
            },

            userRole: "",

            password: "",
            confirmedPassword: "",


            avatars: [],
            isActive: null,
            newAvatar: '',

            languages: [],

            roles: [],

            projectsFields: [{
                    key: 'projectName',
                    label: this.$t('project.projectName'),
                    sortable: false
                },
                {
                    key: 'modDate',
                    label: this.$t('general.modificationData'),
                    sortable: false
                },
                {
                    key: 'targetDate',
                    label: this.$t('project.targetDate'),
                    sortable: false
                },
                {
                    key: 'status',
                    label: this.$t('general.status'),
                    sortable: false,
                },
                {
                    key: 'goTo',
                    label: this.$t('general.goTo'),
                    sortable: false,
                }
            ],

            formulationsFields: [{
                    key: 'name',
                    label: this.$t('formulation.formulationName'),
                    sortable: false
                },
                {
                    key: 'modDate',
                    label: this.$t('general.modificationData'),
                    sortable: false
                },
                {
                    key: 'status',
                    label: this.$t('general.status'),
                    sortable: false,
                },
                {
                    key: 'goTo',
                    label: this.$t('general.goTo'),
                    sortable: false,
                }
            ]

        }


    },
    computed: {
        ...mapGetters({
            logUser: "getUser",
            logUserPermission: "getPermission"
        })
    },

    methods: {

        async getData() {
            this.loading = true;

            let response = await axios.get(this.endpoint + '/' + this.$route.params.id)

            this.user = response.data
            this.confirmedPassword = this.user.password

            let role = await axios.get(this.endpoint + '/' + this.$route.params.id + "/role")

            this.userRole = role.data

            this.loading = false;
            this.isMod = false;

        },

        processForm() {
            let user = null
            if (this.$route.params.id) {
                user = {
                    userName: this.user.userName,
                    avatar: this.user.avatar,
                    firstName: this.user.firstName,
                    lastName: this.user.lastName,
                    email: this.user.email,
                    phoneNumber: this.user.phoneNumber,
                    address: this.user.address,
                    language: this.user.language,
                    role: this.$route.params.id != undefined ? this.userRole._links.self.href : this.userRole,
                }
            } else {
                user = {
                    userName: this.user.userName,
                    password: this.user.password,
                    avatar: this.user.avatar,
                    firstName: this.user.firstName,
                    lastName: this.user.lastName,
                    email: this.user.email,
                    phoneNumber: this.user.phoneNumber,
                    address: this.user.address,
                    language: this.user.language,
                    role: this.$route.params.id != undefined ? this.userRole._links.self.href : this.userRole,
                }
            }
            return user
        },

        async postData(endpoint, data) {
            try {
                //Post informations data
                let user = await axios.post(endpoint, data);

                let id = user.data._links.self.href.split("/").pop();

                this.isMod = false;
                this.succesAlert()
                this.$router.push(this.endpoint + '/' + id);
                this.getData();

            } catch (error) {
                this.errorAlert(error);
            }
        },

        async putData(endpoint, data) {
            try {
                //Update informations
                await axios.put(endpoint, data);

                this.isMod = false;
                this.succesAlert()

            } catch (error) {
                this.errorAlert(error);
            }
        },

        fetchProjects() {

            var filter = "/search/findByOrderByModDateDesc";
            var paramValue = {
                "page": 0,
                "size": 5,
            };
            const promise = axios.get("project" + filter, {
                params: paramValue
            })

            return promise.then(data => {
                const items = data.data._embedded.project
                this.totalProject = data.data.page.totalElements
                // Must return an array of items or an empty array if an error occurred
                return items || []
            })
        },

        fetchFormulations() {
            var filter = "/search/findByOrderByModDateDesc";
            var paramValue = {
                "page": 0,
                "size": 5,
            };
            const promise = axios.get("formula" + filter, {
                params: paramValue
            })

            return promise.then(data => {
                const items = data.data._embedded.formula
                this.totalFormulation = data.data.page.totalElements
                // Must return an array of items or an empty array if an error occurred
                return items || []
            })
        },

        navigateTo(url) {
            let id = url.split("/").pop()
            let file = url.split("/")[4]
            if (file == "formula") {
                file = "formulation"
            }
            this.$router.push(file + '/' + id)
        },

        changeSelectedAvatar(avatarPath, index) {
            this.isActive = (index == this.isActive ? '' : index);
            this.newAvatar = avatarPath

        },

        saveAvatar() {
            this.user.avatar = this.newAvatar.split("/").pop()
            if (this.$route.params.id) {
                this.save()
            }
        },

        savePreferences() {
            this.save()
            this.$bvModal.hide('preferences')
        },

        showModal(ref) {
            this.$refs[ref].show()
        },

        importAll(r) {
            r.keys().forEach(key => (this.avatars.push({
                pathLong: r(key),
                pathShort: key
            })));

        },

        async loadOptionFields() {

            axios
                .get('language')
                .then((response) => {
                    this.languages = response.data._embedded.hashMaps
                })

            axios
                .get('role')
                .then((response) => {
                    this.roles = response.data._embedded.role
                })
        }

    },
    created() {

        this.loadOptionFields()

        this.importAll(require.context('../assets/avatar/', true, /\.png$/))

        if (this.$route.params.id != undefined) {
            this.getData();
        } else {
            this.loading = false;
        }

    },

    validations: {

        userRole: {
            required
        },
        user: {
            userName: {
                required
            },
            password: {
                required,
                minLength: minLength(6)
            },
            firstName: {
                required
            },
            lastName: {
                required
            },
            email: {
                email
            },
            address: {
                maxLength: maxLength(500)
            }
        },
        confirmedPassword: {
            sameAsPassword: sameAs(function() {
                return this.user.password
            })
        }
    }
};
</script>

<style scoped>
.card-projects {
    border-color: #528061
}

.card-header-projects {
    background-color: #528061;
    opacity: 0.8
}

.card-formulations {
    border-color: #527180
}

.card-header-formulations {
    background-color: #527180;
    opacity: 0.8
}

.active {
    outline: 1px dashed #528061;
    outline-offset: -10px;
}
</style>
