<template>
    <div>
        <ListingGrid :column="auditColumn" :endpoint=' this.$route.path + "/revisions?fetchChanges=true" ' dataObject="auditDtoes" :hideCreateButton='true' :redirectUrl=' this.$route.path + this.$route.params.id ' ></ListingGrid>
    </div>
</template>
    
<script>
import CommonVariablesAndFunctions from '../mixins/CommonVariablesAndFunctions.js'
import Feedback from '../mixins/Feedback.js'
import ListingGrid from '../components/ListingGrid.vue';

export default {
    name: 'Audit',
    mixins: [Feedback, CommonVariablesAndFunctions],
    components: {
        ListingGrid
    },
    data() {
        return {
            title: 'Contact Audit',
            auditColumn: [{
                label: 'Mod By',
                key: 'modBy',
                sortable: true
            },
            {
                label: 'Mod Date',
                key: 'modDate',
                sortable: true
            },
            {
                label: 'Changes Type',
                key: 'changesType',
                sortable: true
            },
            {
                label: 'Changes',
                key: 'fieldChanged',
                sortable: true
            },
            {
                label: 'Previous Value',
                key: 'previousValue',
                sortable: true
            },
            {
                label: 'Revision',
                key: 'revision',
                sortable: true
            }],
        }
    },
    props: {

    },
    methods: {


    },
    computed: {

    },
}
</script>
    