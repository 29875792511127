<template>
<div v-can="'Read_Material'">

    <div class="container-fluid">
        <div class="row">

            <div class="col-md-12">

                <h2 class="text-left" v-if="$route.name == 'Material'"><router-link to="/materials">{{$tc('general.material', 1)}}</router-link></h2>

                <LayoutCard :title="$t('general.informations')" active="true">

                    <template v-slot:header-options>
                        <DeleteButton v-can="'Delete_Material'" endpoint="material" :dataToDisplay="objectData.tradeName" searchValue="null" :relationship="$store.state.material.associatedData" :toDeleteWithRegister="$store.state.material.endpointToDelete"></DeleteButton>
                    </template>

                    <div class="loader" v-if="loading">
                    </div>

                    <fieldset :disabled="loading">
                        <form @change="save()">

                            <div class="form-row">

                                <div v-if="this.$route.params.id" class="form-group col-md-2">
                                    <label for="inputPrefixId">ID</label>
                                    <input v-can:input="'Update_Material'" type="text" readonly class="form-control" id="inputPrefixId" v-model="objectData.prefixId" />
                                </div>
                                <div v-else class="form-group col-md-2">
                                    <label for="inputId">ID</label>

                                    <v-select id="materialPrefix-list" placeholder="Prefix" v-model="$v.objectData.prefix.$model" :reduce="prefix => prefix.name" :clearable="false" :options="materialPrefixes" label="name" @input="save" :class="validatePrefix($v.objectData.prefix)" class="vselect">
                                    </v-select>
                                    <div class="error" id="prefix-required" v-if="$v.objectData.prefix.$error">{{$t('errorMsg.required')}}</div>
                                </div>

                                <div class="form-group col-md-8">
                                    <label for="inputTradeName">{{$t('material.tradeName')}}</label>
                                    <b-form-input v-can:input="'Update_Material'" type="text" class="form-control" id="inputTradeName" placeholder="Trade name" :state="validateState($v.objectData.tradeName)" @input="getTradeName(objectData.tradeName)" :trim="true"
                                        v-model="$v.objectData.tradeName.$model" />
                                    <div class="invalid-feedback" v-if="!$v.objectData.tradeName.required">{{$t('errorMsg.required')}}</div>
                                    <div class="invalid-feedback" v-if="!$v.objectData.tradeName.isTradeNameUnique">This material name already exist</div>
                                </div>

                                <div class="form-group col-md-2">
                                    <label for="inputStatus">{{$t('general.status')}}</label>
                                    <v-select id="inputMaterialStatus" :placeholder="$t('general.selectStatus')" v-model="$v.objectData.status.$model" :reduce="status => status.id" :selectable="status => status.selectable" :clearable="false" :options="materialStatus" label="name" @input="save" :class="validateStatus($v.objectData.status)"
                                        class="vselect">
                                    </v-select>
                                    <div class="error" id="status-required" v-if="!$v.objectData.status.required && $v.objectData.status.$dirty">{{$t('errorMsg.required')}}</div>
                                </div>

                            </div>

                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <label v-if='!loading && this.$route.params.id'><router-link :to="'/supplier/' + selectedSupplier._links.self.href.split('/').pop()">{{$tc('general.supplier', 1)}}</router-link></label>
                                    <label v-else>{{$tc('general.supplier', 1)}}</label>
                                    <span class="ml-2">
                                        <font-awesome-icon v-can="'Update_Material'" icon="search" class="btn-icon" @click="showModal('supplier')" /></span>

                                    <b-form-input v-can:input="'Update_Material'" list="supplier-list" placeholder="Supplier" autocomplete="off" @input="getSupplier(selectedSupplierName), setSupplier(null)" :state="validateSupplierStateField($v.selectedSupplierName)" :trim="true"
                                        v-model="$v.selectedSupplierName.$model">
                                    </b-form-input>
                                    <div class="invalid-feedback" v-if="!$v.selectedSupplierName.required">{{$t('errorMsg.required')}}</div>
                                    <div class="invalid-feedback" v-if="!$v.selectedSupplierName.supplierMustExist">{{$t('errorMsg.SupplierMustExist')}}</div>
                                    <div class="error small" v-if="isSupplierHasDistributor == false">Supplier has no distributor. Please add one</div>

                                    <datalist id="supplier-list">
                                        <option v-for="supplier in suppliers" :key="supplier._links.self.href" field-value="supplier._links.self.href"> {{ supplier.supplierName }}</option>
                                    </datalist>

                                </div>
                                <div v-if="isSupplierChoosen && $v.selectedSupplierName.supplierMustExist && isSupplierHasDistributor == true" class="form-group col-md-6">

                                    <label v-if='!loading && this.$route.params.id'><router-link :to="getDistributor">{{$tc('general.distributor', 1)}}</router-link></label>
                                    <label v-else>{{$tc('general.distributor', 1)}}</label>

                                    <v-select v-if="!loading" id="inputDistributor" :placeholder="$tc('general.distributor', 1)" v-model="$v.selectedDistributor.$model" :clearable="false" :options="distributors" label="distributorName" @input="save" :class="validateStatus($v.selectedDistributor)"
                                        class="vselect">
                                    </v-select>
                                    <div class="error" id="distributor-required" v-if="$v.selectedDistributor.$model.distributorName == null && $v.selectedDistributor.$dirty">{{$t('errorMsg.required')}}</div>

                                </div>
                            </div>

                            <hr />

                            <div class="form-group">
                                <label for="inputLocation">{{$t('material.location')}}</label>
                                <input v-can:input="'Update_Material'" type="text" class="form-control" id="inputLocation" :placeholder="$t('material.location')" v-model="objectData.location" />
                            </div>

                            <div class="form-row">
                                <div v-if='!loading' class="form-group col-md-12">
                                    <label for="inputFamily">{{$t('material.families')}}</label>
                                    <FamilyList @newMaterialFamily="savedNewMaterialFamily($event)" @removeMaterialFamily="removeMaterialFamily($event)" permission="Update_Project" :savedFamilies="savedFamilies">
                                    </FamilyList>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <label for="inputDescription">{{$t('product.description')}}</label>
                                    <textarea v-can:input="'Update_Material'" class="form-control" id="inputDescription" placeholder="Description name" rows="4" v-model="objectData.description"></textarea>
                                    <div class="error small mt-1" v-if="!$v.objectData.description.maxLength">{{$t('errorMsg.tooLong')}}. ({{500-$v.objectData.description.$model.length}})</div>
                                </div>

                                <div v-can="'Read_FileManager'" class="form-group col-md-6" v-if="this.$route.params.id && !loading">
                                    <label for="inputFiles">{{$t('fileManager.file')}}</label>
                                    <FileManager repositoryName="material" :pathIdList="[this.$route.params.id]"
                                        acceptExtensions=".doc, .docx, .txt, .pdf, .xls, .xlsx" permission="Update_Material"/>
                                </div>
                            </div>

                        </form>
                    </fieldset>
                </LayoutCard>

                <LayoutCard :title="$t('general.composition')">
                    <div>

                        <form>

                            <div class="table-responsive">
                                <table class="table">
                                    <tr>
                                        <th style="width: 40%">{{$tc('general.ingredient', 1)}}</th>
                                        <th style="width: 50%">{{$t('general.percentage')}}</th>
                                        <th style="width: 10%"></th>
                                    </tr>

                                    <tr v-for="(ingredient, index) in $v.composition.$each.$iter" :key="index">
                                        <td>
                                            <b-form-input v-can:input="'Update_Material'" list="ingredients-list" autocomplete="off" placeholder="Ingredient" v-model="ingredient.ingredient.name.$model" @input="getIngredient(ingredient.ingredient.name.$model)"
                                                @blur="saveIngredient(index)">
                                            </b-form-input>
                                            <div class="error small" v-if="!ingredient.ingredient.name.required">{{$t('errorMsg.required')}}</div>
                                            <div class="error small" v-if="!ingredient.ingredient.name.isIngredientUnique">{{$t('errorMsg.ingredientAlreadyExist')}}</div>
                                            <div class="error small" v-if="!ingredient.$model.isIngredientExist">{{$t('errorMsg.IngredientMustExist')}}</div>

                                            <datalist id="ingredients-list">
                                                <option v-for="ingredient in ingredients" :key="ingredient._links.self.href" field-value="ingredient._links.self.href"> {{ ingredient.name }}</option>
                                            </datalist>
                                        </td>
                                        <td v-if="index == 0">
                                            <b-form-input v-can:input="'Update_Material'" :disabled="true" type="number" step=".001" v-model="qspIngredient" />
                                        </td>

                                        <td v-else>
                                            <b-form-input v-can:input="'Update_Material'" type="number" :formatter="formatterIngredientPercentage" step=".001" v-model="composition[index].percentage" @blur="saveIngredient(index)" />
                                        </td>

                                        <td v-can="'Update_Material'">
                                            <font-awesome-icon class="btn-icon" @click="ingredientModal(index)" icon="eye" />
                                            <font-awesome-icon class="btn-icon" @click="deleteRow(index)" icon="trash-alt" />
                                        </td>
                                    </tr>

                                    <tr v-show="composition.length==0">
                                        <td colspan="3" style="text-align:center;height:100px;vertical-align: middle;">{{$t('general.noRecord')}}</td>
                                    </tr>
                                </table>

                            </div>
                            <div class="error small" v-if="!$v.qspIngredient.between">{{$t('errorMsg.negativeValue')}}</div>
                            <div class="error small" v-if="!$v.sumOfIngredient.between">{{$t('errorMsg.sumIs')}} {{sumOfIngredient}} %. {{$t('errorMsg.theSumOfIngredientShouldBe')}} 100%.</div>

                            <div class="d-flex flex-row-reverse mb-4">
                                <font-awesome-icon v-can="'Update_Material'" icon="plus-circle" class="clickable" @click="addNewRow" />
                            </div>

                        </form>
                    </div>
                </LayoutCard>

                <LayoutCard :title="$t('material.organicNatural')">
                      <form @change="saveMaterialOrganic()">
                        <div v-show="!loading" class="form-row">

                            <div class="form-group col-md-4">
                                <label for="accepted">Cosmos :</label>

                            <b-row class="text-center">
                                <b-col cols="4">
                                    <b-form-checkbox v-can:select="'Update_Material'" id="Cosmos" class="ml-2 mb-3"
                                        v-model="materialOrganic.cosmosApproved" name="cosmosApproved">
                                        {{$t('material.approved')}}
                                    </b-form-checkbox>
                                </b-col>
                            </b-row>

                            <span v-if="this.materialOrganic.cosmosApproved == true">
                                <div class="form-group row ml-2">
                                <label for="cosmosOrgPPAI" class="col-md-4 col-form-label">{{$t('material.organic')}} PPAI</label>
                                <div class="col-md-4">
                                    <b-form-input v-can:input="'Update_Material'" :formatter="formatterIngredientPercentage" type="number" min="0" max="100" step=".01" class="form-control" v-model="materialOrganic.cosmosOrgPPAI" />
                                </div>
                                </div>

                                    <div class="form-group row ml-2">
                                    <label for="cosmosOrgCPAI" class="col-md-4 col-form-label">{{$t('material.organic')}} CPAI</label>
                                    <div class="col-md-4">
                                        <b-form-input v-can:input="'Update_Material'" :formatter="formatterIngredientPercentage" type="number" min="0" max="100" step=".01" class="form-control" v-model="materialOrganic.cosmosOrgCPAI" />
                                    </div>
                                    </div>

                                    <div class="form-group row ml-2">
                                    <label for="cosmosPPAI" class="col-md-4 col-form-label">PPAI</label>
                                    <div class="col-md-4">
                                        <b-form-input v-can:input="'Update_Material'" :formatter="formatterIngredientPercentage" type="number" min="0" max="100" step=".01" class="form-control" v-model="materialOrganic.cosmosPPAI" />
                                    </div>
                                    </div>

                                    <div class="form-group row ml-2">
                                    <label for="cosmosCPAI" class="col-md-4 col-form-label">CPAI</label>
                                    <div class="col-md-4">
                                        <input v-can:input="'Update_Material'" :formatter="formatterIngredientPercentage" type="number" min="0" max="100" step=".01" class="form-control" v-model="materialOrganic.cosmosCPAI" />
                                    </div>
                                    </div>

                                    <div class="form-group row ml-2">
                                    <label for="cosmosNNI" class="col-md-4 col-form-label">{{$t('material.nonNatural')}}</label>
                                    <div class="col-md-4">
                                        <input v-can:input="'Update_Material'" :formatter="formatterIngredientPercentage" type="number" min="0" max="100" step=".01" class="form-control" v-model="materialOrganic.cosmosNNI" />
                                    </div>
                                    </div>

                                    <div class="form-group row ml-2">
                                    <label for="cosmosSyMo" class="col-md-4 col-form-label">{{$t('material.petrochemicalMoieties')}}</label>
                                    <div class="col-md-4">
                                        <input v-can:input="'Update_Material'" :formatter="formatterIngredientPercentage" type="number" min="0" max="100" step=".01" class="form-control" v-model="materialOrganic.cosmosSyMo" />
                                    </div>
                                    </div>
                                </span>
                            </div>

                            <div class="form-group col-md-4">
                                <label for="accepted">Natrue :</label>

                            <b-row class="text-center">
                                <b-col cols="4">
                                    <b-form-checkbox v-can:select="'Update_Material'" id="natrueApproved" class="ml-2 mb-3"
                                        v-model="materialOrganic.natrueApproved" name="natrueApproved">
                                        {{$t('material.approved')}}
                                    </b-form-checkbox>
                                </b-col>
                                <b-col cols="4">
                                    <b-form-checkbox v-can:select="'Update_Material'" id="natrueCertified" class="ml-2 mb-3"
                                        v-model="materialOrganic.natrueCertified" name="natrueCertified">
                                        {{$t('material.certified')}}
                                    </b-form-checkbox>
                                </b-col>
                            </b-row>
                            
                            <span v-show="this.materialOrganic.natrueApproved == true || this.materialOrganic.natrueCertified == true">
                                <div class="form-group row ml-2">
                                <label for="natrueNatural" class="col-md-4 col-form-label">{{$t('material.natural')}}</label>
                                <div class="col-md-4">
                                    <b-form-input v-can:input="'Update_Material'" :formatter="formatterIngredientPercentage" type="number" min="0" max="100" step=".01" class="form-control" v-model="materialOrganic.natrueNatural" />
                                </div>
                                </div>

                                    <div class="form-group row ml-2">
                                    <label for="natrueNaturalOrganic" class="col-md-4 col-form-label">{{$t('material.organicOrigin')}}</label>
                                    <div class="col-md-4">
                                        <b-form-input v-can:input="'Update_Material'" :formatter="formatterIngredientPercentage" type="number" min="0" max="100" step=".01" class="form-control" v-model="materialOrganic.natrueNaturalOrganic" />
                                    </div>
                                    </div>

                                    <div class="form-group row ml-2">
                                    <label for="natrueDerivedNatural" class="col-md-4 col-form-label">{{$t('material.derivatedNatural')}}</label>
                                    <div class="col-md-4">
                                        <b-form-input v-can:input="'Update_Material'" :formatter="formatterIngredientPercentage" type="number" min="0" max="100" step=".01" class="form-control" v-model="materialOrganic.natrueDerivedNatural" />
                                    </div>
                                    </div>

                                    <div class="form-group row ml-2">
                                    <label for="isoOrganicOrigin" class="col-md-4 col-form-label">{{$t('material.derivatedNaturalOutOfOrganic')}}</label>
                                    <div class="col-md-4">
                                        <input v-can:input="'Update_Material'" :formatter="formatterIngredientPercentage" type="number" min="0" max="100" step=".01" class="form-control" v-model="materialOrganic.natrueDerivedNaturalOutOfOrganic" />
                                    </div>
                                    </div>

                                    <div class="form-group row ml-2">
                                    <label for="natrueDerivedNaturalOutOfOrganic" class="col-md-4 col-form-label">{{$t('material.naturelIdentical')}}</label>
                                    <div class="col-md-4">
                                        <input v-can:input="'Update_Material'" :formatter="formatterIngredientPercentage" type="number" min="0" max="100" step=".01" class="form-control" v-model="materialOrganic.natrueNatureIdentical" />
                                    </div>
                                    </div>

                                    <div class="form-group row ml-2">
                                    <label for="natrueWater" class="col-md-4 col-form-label">{{$t('material.water')}}</label>
                                    <div class="col-md-4">
                                        <input v-can:input="'Update_Material'" :formatter="formatterIngredientPercentage" type="number" min="0" max="100" step=".01" class="form-control" v-model="materialOrganic.natrueWater" />
                                    </div>
                                    </div>
                                </span>

                            </div>


                            <div class="form-group col-md-4">
                                <label for="Iso">{{$t('material.Iso16128')}} :</label>

                                <div class="form-group row ml-2">
                                <label for="isoNatural" class="col-md-4 col-form-label">{{$t('material.natural')}}</label>
                                <div class="col-md-4">
                                    <b-form-input v-can:input="'Update_Material'" :formatter="formatterOrganicIso" type="number" min="0" max="1" step=".01" class="form-control" v-model="materialOrganic.isoNatural" />
                                </div>
                                </div>

                                <div class="form-group row ml-2">
                                <label for="isoNaturalOrigin" class="col-md-4 col-form-label">{{$t('material.naturalOrigin')}}</label>
                                <div class="col-md-4">
                                    <b-form-input v-can:input="'Update_Material'" :formatter="formatterOrganicIso" type="number" min="0" max="1" step=".01" class="form-control" v-model="materialOrganic.isoNaturalOrigin" />
                                </div>
                                </div>

                                <div class="form-group row ml-2">
                                <label for="isoOrganic" class="col-md-4 col-form-label">{{$t('material.organic')}}</label>
                                <div class="col-md-4">
                                    <b-form-input v-can:input="'Update_Material'" :formatter="formatterOrganicIso" type="number" min="0" max="1" step=".01" class="form-control" v-model="materialOrganic.isoOrganic" />
                                </div>
                                </div>

                                <div class="form-group row ml-2">
                                <label for="isoOrganicOrigin" class="col-md-4 col-form-label">{{$t('material.organicOrigin')}}</label>
                                <div class="col-md-4">
                                    <input v-can:input="'Update_Material'" :formatter="formatterOrganicIso" type="number" min="0" max="1" step=".01" class="form-control" v-model="materialOrganic.isoOrganicOrigin" />
                                </div>
                                </div>

                            </div>

                        </div>
                    </form>
                    </LayoutCard>

                <LayoutCard :title="$t('general.price')">

                    <div>
                        <form @change="save()">

                            <div class="form-row">
                                <div class="form-group col-md-12">
                                    <label for="inputMaterialPrice">{{$t('general.priceKg')}}</label>

                                    <b-form-input v-can:input="'Update_Material'" type="number" :formatter="formatterPrice" class="form-control" id="inputMaterialPrice" placeholder="Ingredient Price" :state="validateState($v.materialDistributorPrice)" :lazy="true"
                                        v-model="materialDistributorPrice" />

                                    <div class="invalid-feedback" v-if="!$v.materialDistributorPrice.required">{{$t('errorMsg.required')}}</div>
                                </div>
                            </div>

                        </form>
                    </div>
                </LayoutCard>

                <div v-if="isMod">
                    <SaveCancelDeleteButtons permission="Create_Material" @clicked="getClicked" :isError="$v.$anyError"></SaveCancelDeleteButtons>
                </div>

                <div class="fixed-top mt-3 d-flex justify-content-center">
                    <b-alert :show="dismissCountDown" dismissible fade :variant="alertType" @dismiss-count-down="countDownChanged">
                        {{ feedbackMessage }}
                    </b-alert>
                </div>

                <LayoutCard v-if="this.$route.params.id" :title="$t('general.history')">
                    <History :dataType='"material"' :addBy="objectData.addBy" :addDate="objectData.addDate" :lastModBy="objectData.modBy" :lastMod="objectData.modDate" />
                </LayoutCard>

                <div>
                    <ModalWindow v-show="openedModal === 'supplier'" :title="$t('general.search')" @close="openedModal = null">
                        <MainGrid gridTitle="Supplier" objectNodeName="entityModels" endpoint="/supplier" :gridColumns="columns" :filter="filter" formUrl="/supplier" @close="openedModal=null" @selectValue="setSupplier" :queryByFilter="true"
                            :customOrderBy="true"></MainGrid>
                    </ModalWindow>

                </div>

                <div>
                    <b-modal ref="ingredient-more-info-modal" size="xl" scrollable :title="$tc('general.ingredient', 1)">
                        <Ingredient :ingredientId="modalIngredientId"></Ingredient>
                        <template #modal-footer="{ ok }">
                            <b-button variant="primary" @click="ok()">OK</b-button>
                        </template>
                    </b-modal>
                </div>

            </div>
        </div>

    </div>

</div>
</template>

<script>
import axios from 'axios';
import History from '../components/History.vue';
import ModalWindow from '../components/ModalWindow.vue';
import Ingredient from '../views/Ingredient.vue';
import DeleteButton from '../components/DeleteButton.vue';
import FileManager from '../components/FileManager';
import FamilyList from '../components/FamilyList.vue';
import LayoutCard from '../components/LayoutCard.vue';
import MainGrid from '../components/MainGrid.vue';
import SaveCancelDeleteButtons from '../components/SaveCancelDeleteButtons.vue';
import {
    required,
    between,
    maxLength
} from 'vuelidate/lib/validators';

import {
    mapActions
} from "vuex";

function isIngredientUnique(value) {
    if (this.composition.filter(e => e.ingredient.name === value).length == 1) {
        return true
    }
    return false
}

function isTradeNameUnique(value) {
    if (this.materials.filter(e => e.tradeName.toLowerCase() === value.toLowerCase()).length == 0) {
        return true
    }
    return false
}

function isPrefixSelected(value) {
    if ((this.selectedMaterialPrefix !== undefined && this.selectedMaterialPrefix._links.self.href) || (value || this.selectedMaterialPrefixLink)) {
        return true
    }
    return false
}

function supplierMustExist(value) {
    if (this.suppliers.filter(e => e.supplierName === value).length != 0) {
        return true
    }
    return false
}

import Feedback from '../mixins/Feedback.js'
import CommonVariablesAndFunctions from '../mixins/CommonVariablesAndFunctions.js'

window.axios = require("axios");

const MATERIAL_PREFIX_SIZE = 300
const MATERIAL_PREFIX_SORT = 'name, asc'

export default {
    name: 'Material',

    mixins: [Feedback, CommonVariablesAndFunctions],

    components: {
        History,
        ModalWindow,
        Ingredient,
        FileManager,
        FamilyList,
        DeleteButton,
        LayoutCard,
        MainGrid,
        SaveCancelDeleteButtons,
    },

    props: {
        materialId: {
            required: false
        }
    },

    data() {
        return {

            title: "Material",
            endpoint: 'material',

            isSupplierChoosen: false,

            materials: [],

            materialPrefixes: [],

            materialOrganic: {
                cosmosApproved : false,
                ecocertApproved : false,
                natrueApproved : false,
                natrueCertified : false,
                isoNatural : null,
                isoNaturalOrigin : null,
                isoOrganic : null,
                isoOrganicOrigin : null,
                cosmosOrgPPAI: null,
                cosmosPPAI: null,
                cosmosOrgCPAI: null,
                cosmosNNI: null,
                cosmosCPAI: null,
                cosmosSyMo: null,
                natrueNatural: null,
                natrueDerivedNatural: null,
                natrueNaturalOrganic: null,
                natrueNatureIdentical: null,
                natrueDerivedNaturalOutOfOrganic: null,
                natrueWater: null
            },


            suppliers: [],
            selectedSupplier: {},
            selectedSupplierName: "",
            isSupplierHasDistributor: null,

            distributors: [],
            selectedDistributor: {},

            materialFamilies: [],
            savedFamilies : [],

            ingredients: [],
            selectedIngredientName: [],

            composition: [],
            modalIngredientId: null,

            message: null,
            openedModal: null,

            columns: [{
                    label: this.$t('supplier.supplierName'),
                    key: "supplierName",
                    sortable: true,
                    initial_sort: true
                },
                {
                    label: this.$t('distributor.distributorName'),
                    key: "distributor.0.distributorName",
                    sortable: true,
                    initial_sort: true
                },
                {
                    label: this.$t('contact.firstname'),
                    key: "contact.0.firstName",
                    sortable: true,
                    initial_sort: true
                },
                {
                    label: this.$t('contact.lastName'),
                    key: "contact.0.lastName",
                    sortable: true,
                    initial_sort: true
                },
                {
                    key: "returnButton",
                }
            ],
            filter: [{
                method: "findBySupplierDistributorContact",
                params: ["searchValue"]
            }],

            objectData: {
                addBy: "",
                addDate: "",
                modBy: "",
                modDate: "",
                prefix: "",
                prefixId: "",
                tradeName: "",
                description: "",
                location: "",
                status: "",
                familiesNames: [],
                ingredientItemOfFormulation: [{
                    addBy: "",
                    addDate: "",
                    modBy: "",
                    modDate: "",
                    percentage: null
                }],
                formulaMaterial: [{
                    addBy: "",
                    addDate: "",
                    modBy: "",
                    modDate: "",
                    percentage: null,
                    phase: "",
                    qsp: true
                }],
                materialDistributor: [{
                    addBy: "",
                    addDate: "",
                    modBy: "",
                    modDate: "",
                    price: null
                }],
                materialIngredient: [{
                    addBy: "",
                    addDate: "",
                    modBy: "",
                    modDate: "",
                    percentage: null
                }],
            }
        };
    },
    computed: {

        selectedMaterialPrefixLink: function() {
            if (this.objectData.prefix !== undefined && this.objectData.prefix != "") {
                let link = this.materialPrefixes.filter(prefix => prefix.name.replace(/  +/g, ' ') == this.objectData.prefix)[0]._links.self.href
                return link
            }
            return null
        },

        sumOfIngredient: function() {
            let sumWithoutQsp = 0.0000000;
            for (let i = 1; i < this.composition.length; i++) {
                sumWithoutQsp += parseFloat(this.composition[i].percentage)
            }

            let sum = sumWithoutQsp + this.qspIngredient

            return sum.toPrecision(6)
        },

        qspIngredient: function() {
            let sumOfOtherIngredient = 0.0000000;
            for (let i = 1; i < this.composition.length; i++) {
                sumOfOtherIngredient += parseFloat(this.composition[i].percentage)
            }

            let sum = 100 - sumOfOtherIngredient

            return Math.round((sum + Number.EPSILON) * 1000000000) / 1000000000
        },

        materialDistributorPrice: {
            get: function() {
                if(this.objectData.materialDistributor 
                && this.objectData.materialDistributor.length > 0
                && this.objectData.materialDistributor[0].price) {
                    return this.objectData.materialDistributor[0].price
                }
                return null
            },
            set: function(value) {
                this.objectData.materialDistributor[0].price = parseFloat(value)
            }
        },

        materialStatus: function() {

            if (this.$store.getters.getStatusMaterialINCIRegulation.rawMaterial.length == 0) {
                this.statusMaterialINCIRegulation('rawMaterial')
            }

            return this.$store.getters.getStatusMaterialINCIRegulation.rawMaterial
        },

        getDistributor: function() {
            if(this.selectedDistributor._links.self) {
                return '/distributor/' + this.selectedDistributor._links.self.href.split('/').pop()
            }
            return ''
        },

    },
    methods: {
        ...mapActions(["statusMaterialINCIRegulation"]),

        formatterPrice(value) {
            return Math.abs(value)
        },

        formatterIngredientPercentage(value) {
            if(value.search("\\.") == -1){
                return value < 100 ? Math.abs(value) : 100
            }else{
                return value < 100 ? value : 100
            }
        },

        formatterOrganicIso(value) {
            if(value.search("\\.") == -1){
                return value < 1 ? Math.abs(value) : 1
            }else{
                return value < 1 ? value : 1
            }
        },


        async getSavedFamilies(){
            let familyMaterial = await axios.get(this.endpoint + '/' + this.$route.params.id + '/familyMaterial')
            this.savedFamilies = familyMaterial.data._embedded.familyMaterial
            for (let i = 0; i < this.savedFamilies.length; i++) {
                let response = await axios.get(this.savedFamilies[i]._links.family.href)
                this.savedFamilies[i].familyLink = response.data._links.family.href
            }
        },

        savedNewMaterialFamily(data){
            if(this.$route.params.id){
                let familyMaterial = {
                    family: data._links.family.href,
                    material : "material/" + this.$route.params.id
                }

                axios.post('familyMaterial', familyMaterial)
                    .then(() => {
                        this.getSavedFamilies()
                        this.succesAlert()
                    })
                    .catch( (error) => {
                        this.errorAlert(error);
                    })
            }else{
                this.savedFamilies.push(data)
            }


        },

        removeMaterialFamily(data){
            if(!this.$route.params.id){
                return
            }

            let familyMaterial = this.savedFamilies.find(element => element.familyLink == data._links.family.href)

            axios.delete(familyMaterial._links.self.href)
                .then(() => {
                    this.succesAlert()
                    this.getSavedFamilies()
                })
                .catch( (error) => {
                    this.errorAlert(error);
                })
        },

        ingredientModal(index) {
            this.modalIngredientId = this.composition[index].ingredient.url.split('/').pop()
            this.$refs['ingredient-more-info-modal'].show()
        },

        async saveIngredient(index) {

            //if it is posting new register or if the sum is wrong we needs to ignore the rest of this function
            if (!this.$route.params.id || this.$v.sumOfIngredient.$invalid || this.$v.qspIngredient.$invalid || this.$v.composition.$invalid) {
                return
            }

            await this.updateIngredientUrl(index)

            //if updateIngredientUrl couldn't return an ingredient don't save and return
            if(this.composition[index].isIngredientExist == false){
                return
            }

            if(index == 0){
                this.composition[index].percentage = this.qspIngredient
            }

            //organize data
            let compositionData = {
                percentage: this.composition[index].percentage,
                ingredient: this.composition[index].ingredient.url,
                material: "material/" + this.$route.params.id
            };

            //perform a post because if it's a new line
            if (this.composition[index].materialIngredientLink == "") {
                this.postIngredient(index, compositionData)
            } else {
                //get the link of the ingredient on server
                let materialIngredient = await axios.get(this.composition[index].materialIngredientLink);
                let serverIngredient = await axios.get(materialIngredient.data._links.ingredient.href);
                let serverIngredientLink = serverIngredient.data._links.self.href

                //if the ingredient is the same it's a put (the user update the %)
                //if not delete the old relationship and post a new one
                if (this.composition[index].ingredient.url == serverIngredientLink) {
                    this.putIngredient(index, compositionData)
                } else {
                    //delete the relationship
                    await axios.delete(this.composition[index].materialIngredientLink, {
                        headers: {
                            "X-HTTP-Method-Override": "DELETE"
                        }
                    });
                    //post a new one and update this.composition
                    this.postIngredient(index, compositionData)

                }
            }
            // update the % of qspIngredient except if the change is in index 0 (user can only change material name)
            if(index != 0){
                let compositionQSP = {
                    percentage: this.qspIngredient,
                    ingredient: this.composition[0].ingredient.url,
                    material: "material/" + this.$route.params.id
                };
                this.putIngredient(0, compositionQSP)
                // axios.put(this.composition[0].materialIngredientLink, compositionQSP)
                // .catch((error) => {
                //     console.log(error)
                // })
            }

        },

        async updateIngredientUrl(index){

            this.composition[index].ingredient.name = this.composition[index].ingredient.name.charAt(0).toUpperCase() + this.composition[index].ingredient.name.slice(1)

            //update the url on this.composition.ingredient.url
            var filter = "/search/findByName";
            var paramValue = {
                "name": this.composition[index].ingredient.name,
            };
            let responseIngredients = await axios.get("ingredient" + filter, {
                params: paramValue
            })

            if(responseIngredients.data.length != 0){
                if(this.composition[index].ingredient.name == responseIngredients.data.name){
                    this.composition[index].isIngredientExist = true
                    this.composition[index].ingredient.url = responseIngredients.data._links.ingredient.href
                }else{
                    this.composition[index].isIngredientExist = false
                    return
                }
            }else{
                this.composition[index].isIngredientExist = false
                return
            }
        },

        postIngredient(index, compositionData){

            axios
                .post('materialIngredient', compositionData)
                .then((response) => {
                    this.composition[index].materialIngredientLink = response.data._links.self.href
                    this.succesAlert();
                })
                .catch((error) => {
                    this.errorAlert(error)
                })
        },

        putIngredient(index, compositionData){

            axios
                .put(this.composition[index].materialIngredientLink, compositionData)
                .then(() => {
                    this.succesAlert();
                })
                .catch((error) => {
                    console.log(error);
                    this.errorAlert(error)
                })
        },


        addNewRow() {

            //add new row
            this.composition.push({
                ingredient: {
                    name: "",
                    url: ""
                },
                percentage: this.composition.length > 0 ? "0" : "100",
                materialIngredientLink: "",
                isIngredientExist: true
            });

        },

        deleteRow(index) {

            //delete row at table and post delete method to api
            if (this.$route.params.id) {
                let urlDelete = this.composition[index].materialIngredientLink;
                axios.delete(urlDelete)
                    .then(() => {
                        this.composition.splice(index, 1);
                        //update the qsp ingredient only if there is one
                        if(this.composition.length != 0){
                            let compositionQSP = {
                                percentage: this.qspIngredient,
                                ingredient: this.composition[0].ingredient.url,
                                material: "material/" + this.$route.params.id
                            };
                            this.putIngredient(0, compositionQSP)
                        }
                    })
                    .catch((error) => {
                        console.log(error.response)
                        this.errorAlert(error)
                    })

                //if it is posting new just remove the table row
            } else {
                this.composition.splice(index, 1);
            }

        },

        async getData() {
            this.loading = true;
            this.composition = [];
            this.selectedSupplierName = "";

            let response = await axios.get(this.endpoint + '/' + this.$route.params.id);
            this.objectData = response.data;

            //get supplier name
            let supplier = await axios.get(this.endpoint + '/' + this.$route.params.id + '/supplier')
            this.selectedSupplier = supplier.data
            this.selectedSupplierName = supplier.data.supplierName

            await this.setSupplier(supplier.data._links.self.href);

            //get material prefix
            let materialPrefix = await axios.get(this.endpoint + '/' + this.$route.params.id + '/prefix');
            this.selectedMaterialPrefix = materialPrefix.data;

            //get distributor name
            let materialDistributors = await axios.get(this.endpoint + '/' + this.$route.params.id + '/materialDistributors')
            let distributorLink = ''
            if(materialDistributors.data._embedded.materialDistributor && materialDistributors.data._embedded.materialDistributor.length > 0) {
                distributorLink = materialDistributors.data._embedded.materialDistributor[0]._links.distributor.href
            }

            let distributor = await axios.get(distributorLink)
            this.selectedDistributor = distributor.data

            //get familyMaterial
            await this.getSavedFamilies()


            //get all ingredients
            let materialIngredients = await axios.get(this.endpoint + '/' + this.$route.params.id + '/materialIngredients')
            for (let i = 0; i < materialIngredients.data._embedded.materialIngredient.length; i++) {
                let ingredientPercentage = materialIngredients.data._embedded.materialIngredient[i].percentage;
                let materialIngredientLink = materialIngredients.data._embedded.materialIngredient[i]._links.self.href;
                let materialIngredientEndpoint = materialIngredients.data._embedded.materialIngredient[i]._links.ingredient.href;

                let ingredientData = await axios.get(materialIngredientEndpoint)

                let ingredientName = ingredientData.data.name
                let ingredientUrl = ingredientData.data._links.self.href
                this.composition.push({
                    ingredient: {
                        name: ingredientName,
                        url: ingredientUrl
                    },
                    percentage: ingredientPercentage,
                    materialIngredientLink: materialIngredientLink,
                    isIngredientExist: true
                })
            }

            this.composition.sort((a, b) => (a.percentage < b.percentage) ? 1 : ((b.percentage < a.percentage) ? -1 : 0))

            this.getMaterialOrganic();

            this.isMod = false;

        },

        async putData(endpoint, data) {

            if (this.isSupplierHasDistributor == false) {
                return
            }

            try {

                //put basic data (supplier is include in data)
                let response = await axios.put(endpoint, data);

                // // let materialIngredientLink = response.data._links.materialIngredients.href;
                let materialDistributorsLink = response.data._links.materialDistributors.href;

                // get the distributor information and update the data
                let materialDistributors = await axios.get(materialDistributorsLink)
                let materialDistributorLink = materialDistributors.data._embedded.materialDistributor[0]._links.self.href;

                let distributorData = await axios.get(materialDistributorLink + '/distributor')

                let distributorServerLink = distributorData.data._links.self.href;

                let materialDistributorData = {
                    material: endpoint,
                    distributor: this.selectedDistributor._links.self.href,
                    price: this.objectData.materialDistributor[0].price,
                }

                if (distributorServerLink == this.selectedDistributor._links.self.href) {
                    axios.put(materialDistributorLink, materialDistributorData)
                } else {
                    await axios.delete(materialDistributorLink)
                    axios.post('materialDistributor', materialDistributorData)
                }

                this.succesAlert()
                //this.getData()

            } catch (error) {
                console.log(error);
                this.errorAlert(error);
            }

        },

        async postData(endpoint, data) {

            if (this.isSupplierHasDistributor == false) {
                return
            }

            try {
                //post materialOrganic and get url to link with material
                let organic = await axios.post("materialOrganic", this.materialOrganic)
                this.materialOrganic = organic.data
                data.materialOrganic = this.materialOrganic._links.self.href

                //post material basic info
                let material = await axios.post(endpoint, data)

                let materialLink = material.data._links.self.href;
                let id = material.data._links.self.href.split("/").pop();
                //post families
                for (var i = 0; i < this.savedFamilies.length; i++) {
                    let data = {
                        material: materialLink,
                        family: this.savedFamilies[i]._links.self.href,
                    }

                    await axios.post('familyMaterial', data)
                }

                let materialDistributorData = {
                    material: materialLink,
                    distributor: this.selectedDistributor._links.self.href,
                    price: this.objectData.materialDistributor[0].price,
                }

                await axios.post('materialDistributor', materialDistributorData)

                if(this.composition.length > 0){
                    this.composition[0].percentage = this.qspIngredient

                    for (let i = 0; i < this.composition.length; i++) {
                        await this.updateIngredientUrl(i)

                        let compositionData = {
                            percentage: this.composition[i].percentage,
                            ingredient: this.composition[i].ingredient.url,
                            material: materialLink
                        };

                        this.postIngredient(i, compositionData)
                    }
                }

                this.succesAlert()

                let materialPrefix = await axios.get(this.endpoint + '/' + id + '/prefix');
                this.selectedMaterialPrefix = materialPrefix.data;

                this.$router.push(this.endpoint + '/' + id);

                this.loading = true

                // partial get data to get the generated ID + supplier + distributor for the label link
                let response = await axios.get(this.endpoint + '/' + this.$route.params.id);
                this.objectData = response.data;

                let supplier = await axios.get(this.endpoint + '/' + this.$route.params.id + '/supplier')
                this.selectedSupplier = supplier.data
                this.selectedSupplierName = supplier.data.supplierName

                let materialDistributors = await axios.get(this.endpoint + '/' + this.$route.params.id + '/materialDistributors')
                let distributorLink = ''
                if(materialDistributors.data._embedded.materialDistributor && materialDistributors.data._embedded.materialDistributor.length > 0) {
                    distributorLink = materialDistributors.data._embedded.materialDistributor[0]._links.distributor.href
                }
                let distributor = await axios.get(distributorLink)
                this.selectedDistributor = distributor.data

                let materialOrganicResponse = await axios.get(this.endpoint + '/' + this.$route.params.id + '/materialOrganic')
                this.materialOrganic = materialOrganicResponse.data

                await this.getSavedFamilies()

                this.isMod = false;
                this.loading = false

            } catch (error) {
                console.log(error)
                this.errorAlert(error);
            }
        },

        processForm() {

            let materialPrefixLink = this.selectedMaterialPrefix ? this.selectedMaterialPrefix._links.self.href : this.selectedMaterialPrefixLink

            let data = {
                prefix: materialPrefixLink,
                tradeName: this.objectData.tradeName.charAt(0).toUpperCase() + this.objectData.tradeName.slice(1),
                status: this.objectData.status,
                location: this.objectData.location,
                description: this.objectData.description,
                supplier: this.selectedSupplier._links.self.href,
                materialOrganic: this.$route.params.id ? this.materialOrganic._links.self.href : ""
            }

            return data
        },

        async getTradeName(input) {
            if (input.length > 1) {
                var filter = "/search/findByTradeNameContainingIgnoreCaseOrderByTradeName";
                var paramValue = {
                    "tradeName": input,
                };
                let response = await axios.get("material" + filter, {
                    params: paramValue
                })
                this.materials = response.data._embedded.material;
            }
        },

        showModal(type) {
            this.openedModal = type
        },

        async getSupplier(selectedSupplierName) {
            if (selectedSupplierName.length > 1) {
                var filter = "/search/findBySupplierNameContainingIgnoreCaseOrderBySupplierName";
                var paramValue = {
                    "supplierName": selectedSupplierName,
                };
                let responseSuppliers = await axios.get("supplier" + filter, {
                    params: paramValue
                })
                this.suppliers = responseSuppliers.data._embedded.supplier;
            }
        },

        async setSupplier(value) {

            //check if the user delete his input
            if (this.selectedSupplierName.length == 0 && value == null) {
                return
            }

            if (value == null) {
                this.selectedSupplier = this.suppliers.filter(supplier => supplier.supplierName == this.selectedSupplierName)[0]
                if (this.selectedSupplier === undefined) {
                    return
                }

                this.suppliers.push(this.selectedSupplier)
                this.selectedSupplierName = this.selectedSupplier.supplierName

            } else {
                let responseSupplier = await axios.get(value);
                this.selectedSupplier = responseSupplier.data

                this.suppliers.push(responseSupplier.data)
                this.selectedSupplierName = responseSupplier.data.supplierName
            }

            let response = await axios.get(this.selectedSupplier._links.distributors.href)
            this.distributors = response.data._embedded.distributor
            this.distributors.sort((a,b) => (a.distributorName > b.distributorName) ? 1 : ((b.distributorName > a.distributorName) ? -1 : 0))

            if (this.distributors.length > 0) {
                this.isSupplierHasDistributor = true
            } else {
                this.isSupplierHasDistributor = false
            }
            this.isSupplierChoosen = true;

        },

        validateSupplierStateField(value) {
            let error = this.validateState(value)
            if (error == false || this.isSupplierHasDistributor == false) {
                return false
            }
            if (error == true && this.isSupplierHasDistributor == true) {
                return true
            }
        },

        getMaterialOrganic() {
            axios.get(this.endpoint + '/' + this.$route.params.id + '/materialOrganic')
            .then((response)=>{
                this.materialOrganic = response.data;
                console.log('get')
                console.log(this.materialOrganic)
                this.loading = false;
            }).catch(()=>{
                //to manage old file without materialOrganic
                axios.post('/materialOrganic', this.materialOrganic)
                .then((response)=>{
                    this.materialOrganic = response.data;
                    this.save();
                })
            })

        },

        async saveMaterialOrganic() {
          if (this.$route.params.id) {
            if(this.materialOrganic.cosmosApproved == false){
                this.materialOrganic.cosmosOrgPPAI = 0;
                this.materialOrganic.cosmosOrgCPAI = 0;
                this.materialOrganic.cosmosPPAI = 0;
                this.materialOrganic.cosmosCPAI = 0;
                this.materialOrganic.cosmosNNI = 0;
                this.materialOrganic.cosmosSyMo = 0;
            }

            if(this.materialOrganic.natrueApproved == false && this.materialOrganic.natrueCertified == false){
                this.materialOrganic.natrueNatural = 0;
                this.materialOrganic.natrueNaturalOrganic = 0;
                this.materialOrganic.natrueDerivedNatural = 0;
                this.materialOrganic.natrueDerivedNaturalOutOfOrganic = 0;
                this.materialOrganic.natrueNatureIdentical = 0;
                this.materialOrganic.natrueWater = 0;
            }

                axios.put(this.materialOrganic._links.self.href, this.materialOrganic)
                .then(() => {
                    this.succesAlert()
                })
                .catch((error) => {
                    console.log(error)
                    this.errorAlert(error);
                })
            }
        },

        async getIngredient(name) {
            if (name.length > 1) {
                var filter = "/search/findByNameContainingIgnoreCaseOrderByName";
                var paramValue = {
                    "name": name,
                };
                let responseIngredients = await axios.get("ingredient" + filter, {
                    params: paramValue
                })
                this.ingredients = responseIngredients.data._embedded.ingredient;
            }
        },

        async loadOptionFields() {

            await axios.get('materialFamily')
                .then((response) => {
                    this.materialFamilies = response.data._embedded.hashMaps
                })
        },

    },

    created() {
        this.loadOptionFields()

        if (this._props.materialId != null) {
            this.$route.params.id = this._props.materialId
        }
        if (this.$route.params.id != undefined) {
            this.getData();
        } else {
            axios
                .get('materialPrefix', {
                    params: {
                        size: MATERIAL_PREFIX_SIZE,
                        sort: MATERIAL_PREFIX_SORT
                    }
                })
                .then((response) => {
                    this.materialPrefixes = response.data._embedded.materialPrefix
                })
            this.loading = false;
            this.loaded = true;
        }

    },
    validations: {
        objectData: {
            tradeName: {
                required,
                isTradeNameUnique
            },
            description:{
                maxLength: maxLength(500)
            },
            status: {
                required
            },
            prefix: {
                isPrefixSelected
            },
        },
        materialDistributorPrice: {
            required,
        },
        selectedSupplierName: {
            required,
            supplierMustExist
        },
        selectedDistributor: {
            required,
        },
        composition: {
            $each: {
                ingredient: {
                    name: {
                        required,
                        isIngredientUnique
                    }
                }
            }
        },
        sumOfIngredient: {
            between: between(99.9999, 100.0001)
        },
        qspIngredient: {
            between: between(0.0000, 100.0000)
        }
    },

};
</script>

<style scoped>
.form-group {
    text-align: left;
    font-weight: bold;
}

.table-responsive {
    overflow-x: visible !important;
    overflow-y: visible !important;
}

.error {
    color: crimson;
    font-weight: bold;
}
</style>
