<template>
<div v-can="'Read_Project'">

    <div class="container-fluid">

        <h2 class="text-left"><router-link to="/projects">{{$tc('general.project', 1)}}</router-link></h2>

        <LayoutCard :title="$t('general.informations')" active="true">

            <template v-slot:header-options>
                <DeleteButton v-can="'Delete_Project'" endpoint="project" :dataToDisplay="objectData.projectName" searchValue="null" :relationship="$store.state.project.associatedData"></DeleteButton>
            </template>

            <div class="loader" v-if="loading">
            </div>

            <fieldset :disabled="loading">
                <form @change="save">

                    <div class="form-row">
                        <div class="form-group col-md-7">
                            <label for="inputProjectName">{{$t('project.projectName')}}</label>
                            <b-form-input v-can:input="'Update_Project'" type="text" class="form-control" id="inputProjectName" :placeholder="$t('project.projectName')" :state="validateState($v.objectData.projectName)" :lazy="true" :trim="true"
                                v-model="$v.objectData.projectName.$model" />
                            <div class="invalid-feedback" id="company-name-required" v-if="!$v.objectData.projectName.required">{{$t('errorMsg.required')}}</div>
                        </div>

                        <div class="form-group col-md-3">
                            <label for="inputTarget">{{$t('project.targetDate')}}</label>
                            <b-form-datepicker v-can:datepicker="'Update_Project'" @input="save" id="example-inputTarget" :placeholder="$t('project.targetDate')" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" v-model="objectData.targetDate" class="mb-2">
                            </b-form-datepicker>
                        </div>

                        <div class="form-group col-md-2">
                            <label>{{$t('general.status')}}</label>
                            <v-select id="inputProjectStatus" :placeholder="$t('general.selectStatus')" v-model="$v.objectData.status.$model" :reduce="status => status.id" :selectable="status => status.selectable" :clearable="false" :options="projectStatus" label="name" @input="save" :class="validateStatus($v.objectData.status)"
                                class="vselect">
                            </v-select>
                            <div class="error" id="status-required" v-if="!$v.objectData.status.required && $v.objectData.status.$dirty">{{$t('errorMsg.required')}}</div>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label for="inputConcept">{{$t('project.concept')}}</label>
                            <b-form-textarea v-can:input="'Update_Project'" rows="5" max-rows="10" debounce="500" class="form-control" id="inputConcept" :placeholder="$t('project.concept')" v-model="objectData.concept"></b-form-textarea>
                        </div>
                        <div class="form-group col-md-6">
                            <label for="inputComments">{{$t('general.note')}}</label>
                            <b-form-textarea v-can:input="'Update_Project'" rows="5" max-rows="10" debounce="500" class="form-control" id="inputComments" :placeholder="$t('general.note')" v-model="objectData.comments"></b-form-textarea>
                        </div>
                    </div>

                    <div class="form-row">
                        <div v-can="'Read_FileManager'" class="form-group col-md-12" v-if="this.$route.params.id && !loading">
                            <label for="inputFiles">{{$t('fileManager.file')}}</label>
                            <FileManager repositoryName="project" :pathIdList="[this.$route.params.id]"
                                acceptExtensions=".doc, .docx, .txt, .pdf, .xls, .xlsx" permission="Update_Project"/>
                        </div>
                    </div>

                </form>
            </fieldset>


        </LayoutCard>

        <LayoutCard :title="$tc('general.regulation', 1)" active="true">

            <div class="loader" v-if="loading">
            </div>

            <fieldset :disabled="loading">
                <form @change="save">

                    <div class="form-row">
                        <div v-if='!loading' class="form-group col-md-6">
                            <label for="inputCountryDistribution">{{$t('project.distributionCountry')}}</label>
                            <CountryList @newCountries="save()" permission="Update_Project" :selectedCountries="objectData.countryDistribution" :value.sync="objectData.countryDistribution"></CountryList>
                        </div>

                        <div v-if='!loading' class="form-group col-md-6">
                            <label for="inputCertification">{{$t('project.certification')}}</label>
                            <RegulationList @newRegulations="save()" permission="Update_Project" :selectedRegulations="selectedRegulation" :value.sync="selectedRegulation"></RegulationList>
                            <div class="error" id="certification-required" v-if="!$v.isCertificationSet.certificationSelected && $v.isCertificationSet.$dirty">{{$t('errorMsg.required')}}</div>
                        </div>
                    </div>

                    <div class="form-row">
                        <div v-if='!loading' class="form-group col-md-6">
                            <label for="inputBlacklist">{{$t('project.blacklist')}}</label>
                            <IngredientList @newIngredients="save()" permission="Update_Project" :selectedIngredient="selectedBlacklist" :value.sync="selectedBlacklist"></IngredientList>
                        </div>

                        <div v-if='!loading' class="form-group col-md-6">
                            <label for="inputWhitelist">{{$t('project.whitelist')}}</label>
                            <IngredientList @newIngredients="save()" permission="Update_Project" :selectedIngredient="selectedWhitelist" :value.sync="selectedWhitelist"></IngredientList>
                        </div>
                    </div>

                </form>
            </fieldset>

        </LayoutCard>

        <LayoutCard :title="$tc('general.client', 1)" active="true">

            <div class="loader" v-if="loading">
            </div>

            <div v-if="!isClientSet">
                {{$t('general.noRecord')}}
                <div class="d-flex flex-row-reverse mb-4">
                    <font-awesome-icon v-can="'Update_Project'" icon="plus-circle" class="clickable mr-2" @click="showModal('client')" />
                </div>
            </div>

            <div v-else>

                <fieldset :disabled="loading">

                    <form>

                        <div class="form-row">
                            <div class="form-group col-md-10">
                                <label v-if='!loading && this.$route.params.id'><router-link :to="'/client/' + clientData._links.client.href.split('/').pop()">{{$t('distributor.companyName')}}</router-link></label>
                                <label v-else>{{$t('distributor.companyName')}}</label>
                                <font-awesome-icon v-can="'Update_Project'" icon="search" class="clickable ml-2" @click="showModal('client')" />
                                <input type="text" readonly class="form-control" id="inputCompanyName" :placeholder="$t('distributor.companyName')" v-model="clientData.companyName">
                            </div>

                            <div class="form-group col-md-2">
                                <label>{{$t('general.status')}}</label>
                                <input type="text" readonly class="form-control" v-model="clientStatusName">
                            </div>

                        </div>

                        <div class="form-group">
                            <label for="inputEmail">{{$t('contact.email')}}</label>
                            <input type="text" readonly class="form-control" id="inputEmail" :placeholder="$t('contact.email')" v-model="clientData.email">
                        </div>

                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label for="inputPhone">{{$t('contact.phoneNumber')}}</label>
                                <input type="text" readonly class="form-control" id="inputPhone" :placeholder="$t('contact.phoneNumber')" v-model="clientData.phoneNumber">
                            </div>
                            <div class="form-group col-md-6">
                                <label for="inputFax">{{$t('contact.faxNumber')}}</label>
                                <input type="text" readonly class="form-control" id="inputFax" :placeholder="$t('contact.faxNumber')" v-model="clientData.faxNumber">
                            </div>
                        </div>

                        <hr />
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label for="inputContactFirstName">{{ $t('contact.contactFirstName')}}</label>
                                <input type="text" readonly class="form-control" id="inputContactFirstName" v-model="clientData.contact[0].firstName">
                            </div>

                            <div class="form-group col-md-6">
                                <label for="inputContactName">{{$t('contact.contactLastName')}}</label>
                                <input type="text" readonly class="form-control" id="inputContactName" v-model="clientData.contact[0].lastName">
                            </div>

                        </div>

                        <div class="form-group">
                            <label for="inputContactEmail">{{$t('contact.email')}}</label>
                            <input type="text" readonly class="form-control" id="inputContactEmail" v-model="clientData.contact[0].email">
                        </div>

                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label for="inputContactPhone">{{$t('contact.phoneNumber')}}</label>
                                <input type="text" readonly class="form-control" id="inputContactPhone" v-model="clientData.contact[0].phoneNumber">
                            </div>
                            <div class="form-group col-md-6">
                                <label for="inputContactFax">{{$t('contact.faxNumber')}}</label>
                                <input type="text" readonly class="form-control" id="inputContactFax" v-model="clientData.contact[0].faxNumber">
                            </div>
                        </div>

                    </form>
                </fieldset>
            </div>

            <div class="error" id="client-required" v-if="!$v.isClientSet.clientSelected && $v.isClientSet.$dirty">{{$t('errorMsg.required')}}</div>


        </LayoutCard>

        <div v-if="isMod">

            <SaveCancelDeleteButtons permission="Create_Project" @clicked="getClicked" :isError="$v.$anyError"></SaveCancelDeleteButtons>

        </div>

        <div class="fixed-top mt-3 d-flex justify-content-center">
            <b-alert :show="dismissCountDown" dismissible fade :variant="alertType" @dismiss-count-down="countDownChanged">
                {{ feedbackMessage }}
            </b-alert>
        </div>

        <LayoutCard v-if="!loading && this.$route.params.id" :title="$t('layoutCard.associatedProducts')">
            <ListingGrid :column="productColumn" :endpoint=' "/project/" + this.$route.params.id + "/products" ' dataObject="product" redirectUrl="/product" :parentData="this.$route.params.id"></ListingGrid>
        </LayoutCard>


        <LayoutCard v-if="!loading && this.$route.params.id" :title="$t('general.history')">
            <History :dataType="endpoint" :addBy="objectData.addBy" :addDate="objectData.addDate" :lastModBy="objectData.modBy" :lastMod="objectData.modDate" />
        </LayoutCard>

    </div>

    <ModalWindow v-show="openedModal === 'client'" :title="$t('general.search')" @close="openedModal = null">

        <MainGrid gridTitle="Client" objectNodeName="entityModels" endpoint="client" :gridColumns="columns" :filter="filter" formUrl="/client" :queryByFilter="true" :customOrderBy="true" @close="openedModal = null" @selectValue="setClient">
        </MainGrid>

    </ModalWindow>

</div>
</template>

<script>
import axios from 'axios'
import History from '../components/History.vue'
import LayoutCard from '../components/LayoutCard.vue'
import SaveCancelDeleteButtons from '../components/SaveCancelDeleteButtons.vue'
import ModalWindow from '../components/ModalWindow.vue';
import MainGrid from '../components/MainGrid.vue';
import DeleteButton from '../components/DeleteButton.vue'
import ListingGrid from '../components/ListingGrid.vue';
import moment from 'moment'
import CountryList from '../components/CountryList.vue'
import RegulationList from '../components/RegulationList.vue'
import FileManager from '../components/FileManager';
import IngredientList from '../components/IngredientList.vue'

import Feedback from '../mixins/Feedback.js'
import CommonVariablesAndFunctions from '../mixins/CommonVariablesAndFunctions.js'

import {
    mapActions
} from "vuex";

import {
    required,
    minLength,
} from 'vuelidate/lib/validators'

window.axios = require("axios");

export default {
    name: 'Project',
    endpoint: "project",

    mixins: [Feedback, CommonVariablesAndFunctions],

    components: {
        History,
        LayoutCard,
        DeleteButton,
        SaveCancelDeleteButtons,
        MainGrid,
        ListingGrid,
        CountryList,
        IngredientList,
        RegulationList,
        FileManager,
        ModalWindow,
    },

    data() {
        return {
            title: "Project",
            endpoint: "project",

            openedModal: null,

            isClientSet: false,
            isCertificationSet: false,

            clientData: {
                addBy: "",
                addDate: "",
                address: "",
                city: "",
                companyName: "",
                contact: "",
                country: "",
                email: "",
                faxNumber: "",
                modBy: "",
                modDate: "",
                phoneNumber: "",
                state: "",
                status: "",
                zipCode: "",
                _links: {
                    client: {
                        href: ""
                    },
                    contacts: {
                        href: ""
                    },
                    projects: {
                        href: ""
                    },
                    self: {
                        href: ""
                    },
                }
            },
            clientLink: null,
            clientStatusName: "",
            clientStatus: [],

            selectedRegulation: [],
            selectedBlacklist: [],
            selectedWhitelist: [],

            objectData: {
                addBy: "",
                addDate: "",
                modBy: "",
                modDate: null,
                projectName: "",
                targetDate: "",
                concept: "",
                certification: "",
                comments: "",
                status: "",
                countryDistribution: [],
            },

            columns: [{
                    label: this.$t('client.companyName'),
                    key: "companyName",
                    sortable: true,
                    initial_sort: true
                },
                {
                    label: this.$t('contact.firstname'),
                    key: "contact[0].firstName",
                    sortable: true,
                },
                {
                    label: this.$t('contact.lastName'),
                    key: "contact[0].lastName",
                    sortable: true,
                },
                {
                    label: this.$t('client.countryName'),
                    key: "countryName",
                    sortable: true,
                },
                {
                    key: "returnButton",
                }
            ],
            filter: [{
                method: "findByClientCountryContact",
                params: ["searchValue"]
            }],

            productColumn: [{
                    key: 'productName',
                    label: this.$t('product.productName'),
                    sortable: true
                },
                {
                    key: 'targetDate',
                    label: this.$t('project.targetDate'),
                    sortable: true
                },
                {
                    key: 'status',
                    label: this.$t('general.status'),
                    sortable: true
                },
                {
                    key: 'goTo',
                    label: this.$t('general.goTo'),
                    sortable: false
                },
            ]

        };
    },

    watch: {
        selectedRegulation: function(val) {
            if(val.length>0){
                this.isCertificationSet = true;
            } else {
                this.isCertificationSet = false;
            }
        }
    },

    computed: {
        targetDateToIso: function() {
            let date = moment(this.objectData.targetDate).toISOString()
            return date
        },

        projectStatus: function() {

            if (this.$store.getters.getStatusFormulaProjectProduct.project.length == 0) {
                this.statusFormulaProjectProduct('project')
            }

            return this.$store.getters.getStatusFormulaProjectProduct.project
        },
    },

    methods: {

        ...mapActions(["statusFormulaProjectProduct"]),

        async getData() {
            this.loading = true;

            let response = await axios.get(this.endpoint + '/' + this.$route.params.id)
            this.objectData = response.data

            //get client Data
            let client = await axios.get(this.objectData._links.client.href)
            this.clientData = client.data

            axios
                .get('statusFile/' + this.clientData.status)
                .then((status) => {
                    this.clientStatusName = status.data.name
                })

            //get regulationConformity
            let regulationConformity = await axios.get(this.endpoint + '/' + this.$route.params.id + '/regulationConformity')
            let regulation = regulationConformity.data._embedded.regulation

            for (let i = 0; i < regulation.length; i++) {
                this.selectedRegulation.push(regulation[i]._links.self.href)
            }

            //get blacklist
            let blacklistResponse = await axios.get(this.endpoint + '/' + this.$route.params.id + '/ingredientBlacklist')
            let blacklist = blacklistResponse.data._embedded.ingredient

            for (let j = 0; j < blacklist.length; j++) {
                this.selectedBlacklist.push(blacklist[j]._links.self.href)
            }

            //get whitelist
            let whitelistResponse = await axios.get(this.endpoint + '/' + this.$route.params.id + '/ingredientWhitelist')
            let whitelist = whitelistResponse.data._embedded.ingredient

            for (let j = 0; j < whitelist.length; j++) {
                this.selectedWhitelist.push(whitelist[j]._links.self.href)
            }

            this.isClientSet = true;
            this.isCertificationSet = true;
            this.loading = false;
            this.isMod = false;

        },


        processForm() {

            let project = {
                projectName: this.objectData.projectName.charAt(0).toUpperCase() + this.objectData.projectName.slice(1),
                targetDate: this.targetDateToIso,
                concept: this.objectData.concept,
                certification: this.objectData.certification,
                comments: this.objectData.comments,
                status: this.objectData.status,
                client: this.clientData._links.client.href,

                countryDistribution: this.objectData.countryDistribution
            };

            return project

        },

        async postData(endpoint, data) {

            try {
                //project informations data
                let project = await axios.post(endpoint, data);

                let id = project.data._links.self.href.split("/").pop();

                //post regulationConformity related to project
                if (this.selectedRegulation.length > 0) {
                    let regulationTempString = "";
                    for (var i = 0; i < this.selectedRegulation.length; i++) {
                        regulationTempString += this.selectedRegulation[i] + "\n";
                    }

                    await axios.put(project.data._links.regulationConformity.href, regulationTempString, {
                        headers: {
                            "Content-type": "text/uri-list"
                        }
                    })
                }

                //post ingredient blacklist related to project
                if (this.selectedBlacklist.length > 0) {
                    let blacklistTempString = "";
                    for (var j = 0; j < this.selectedBlacklist.length; j++) {
                        blacklistTempString += this.selectedBlacklist[j] + "\n";
                    }

                    await axios.put(project.data._links.ingredientBlacklist.href, blacklistTempString, {
                        headers: {
                            "Content-type": "text/uri-list"
                        }
                    })
                }

                //post ingredient whitelist related to project
                if (this.selectedWhitelist.length > 0) {
                    let whitelistTempString = "";
                    for (var k = 0; k < this.selectedWhitelist.length; k++) {
                        whitelistTempString += this.selectedWhitelist[k] + "\n";
                    }

                    await axios.put(project.data._links.ingredientWhitelist.href, whitelistTempString, {
                        headers: {
                            "Content-type": "text/uri-list"
                        }
                    })
                }

                this.isMod = false;
                this.succesAlert()
                this.$router.push(this.endpoint + '/' + id);

            } catch (error) {
                console.log(error);
                this.errorAlert(error);
            }

        },

        async putData(endpoint, data) {
            try {
                //Update informations
                let project = await axios.put(endpoint, data);

                //update regulationConformity related to project
                if (this.selectedRegulation.length > 0) {
                    let regulationTempString = "";
                    for (var i = 0; i < this.selectedRegulation.length; i++) {
                        regulationTempString += this.selectedRegulation[i] + "\n";
                    }

                    await axios.put(project.data._links.regulationConformity.href, regulationTempString, {
                        headers: {
                            "Content-type": "text/uri-list"
                        }
                    })
                }
                if (this.selectedRegulation.length == 0) {
                    let regulationTempString = {
                        regulation: ""
                    };
                    await axios.put(project.data._links.regulationConformity.href, regulationTempString)
                }

                //update blacklist related to project
                if (this.selectedBlacklist.length > 0) {
                    let blacklistTempString = "";
                    for (var j = 0; j < this.selectedBlacklist.length; j++) {
                        blacklistTempString += this.selectedBlacklist[j] + "\n";
                    }

                    await axios.put(project.data._links.ingredientBlacklist.href, blacklistTempString, {
                        headers: {
                            "Content-type": "text/uri-list"
                        }
                    })
                }
                if (this.selectedBlacklist.length == 0) {
                    let blacklistTempString = {
                        ingredient: ""
                    };
                    await axios.put(project.data._links.ingredientBlacklist.href, blacklistTempString)
                }

                //update whitelist related to project
                if (this.selectedWhitelist.length > 0) {
                    let whitelistTempString = "";
                    for (var k = 0; k < this.selectedWhitelist.length; k++) {
                        whitelistTempString += this.selectedWhitelist[k] + "\n";
                    }

                    await axios.put(project.data._links.ingredientWhitelist.href, whitelistTempString, {
                        headers: {
                            "Content-type": "text/uri-list"
                        }
                    })
                }
                if (this.selectedWhitelist.length == 0) {
                    let whitelistTempString = {
                        ingredient: ""
                    };
                    await axios.put(project.data._links.ingredientWhitelist.href, whitelistTempString)
                }

                this.isMod = false;
                this.succesAlert()

            } catch (error) {
                console.log(error)
                this.errorAlert(error);
            }

        },

        showModal(type) {
            this.openedModal = type
        },

        async setClient(value) {

            let response = await axios.get(value)

            this.clientData.companyName = response.data.companyName
            this.clientData.phoneNumber = response.data.phoneNumber
            this.clientData.faxNumber = response.data.faxNumber
            this.clientData.email = response.data.email
            this.clientData.status = response.data.status
            this.clientData.contact = response.data.contact
            this.clientData._links.client.href = response.data._links.self.href

            this.clientStatusName = this.clientStatus.filter(status => status.id == this.clientData.status)[0].name

            if (this.$route.params.id) {

                try {
                    this.save();

                } catch (error) {
                    console.log(error);
                    this.errorAlert(error);
                }


            } else {
                this.isClientSet = true;
                this.isMod = true;
            }
        },

        async loadOptionFields() {
            
            axios
                .get('statusFile')
                .then((response) => {
                    this.clientStatus = response.data._embedded.hashMaps
                })

            axios
                .get('country')
                .then((response) => {
                    this.countries = response.data._embedded.hashMaps
                })
        },

    },

    created() {

        this.loadOptionFields()

        if (this.$route.params.id) {
            this.getData();
        } else {
            if (this.$route.query.parentData) {
                this.setClient('client/' + this.$route.query.parentData)
            }
            this.loading = false;
        }
    },

    validations: {
        objectData: {
            projectName: {
                required,
                minLength: minLength(1)
            },
            status: {
                required,
            }
        },
        isClientSet: {
            clientSelected: val => val === true
        },
        isCertificationSet: {
            certificationSelected: val => val === true
        }
    }

};
</script>

<style scoped>
.form-group {
    text-align: left;
    font-weight: bold;
}

.error {
    color: red;
    font-weight: bold;
    font-size: small;
}
</style>
