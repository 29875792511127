import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store/store";
import axios from "axios";
import i18n from '../i18n'

import Home from '../views/Home.vue'
import Contacts from '../views/Contacts.vue'
import Contact from '../views/Contact.vue'
import Clients from '../views/Clients.vue'
import Client from '../views/Client.vue'
import Distributors from '../views/Distributors.vue'
import Distributor from '../views/Distributor.vue'
import Family from '../views/Family.vue'
import Ingredients from '../views/Ingredients.vue'
import Ingredient from '../views/Ingredient.vue'
import Login from '../views/Login.vue'
import Materials from '../views/Materials.vue'
import Material from '../views/Material.vue'
import MaterialPrefix from '../views/MaterialPrefix.vue'
import Nomenclature from '../views/Nomenclature.vue'
import Formulations from '../views/Formulations.vue'
import Formulation from '../views/Formulation.vue'
import FormulaPrefix from '../views/FormulaPrefix.vue'
import Products from '../views/Products.vue'
import Product from '../views/Product.vue'
import Projects from '../views/Projects.vue'
import Project from '../views/Project.vue'
import Regulations from '../views/Regulations.vue'
import RegulationsFamilyGrid from '../views/RegulationsFamilyGrid.vue'
import RegulationsRegulationsGrid from '../views/RegulationsRegulationsGrid.vue'
import RegulationsNomenclaturesGrid from '../views/RegulationsNomenclaturesGrid.vue'
import Regulation from '../views/Regulation.vue'
import SetupPrefix from '../views/SetupPrefix.vue'
import SetupPrefixFormula from '../views/SetupPrefixFormula.vue'
import SetupPrefixMaterial from '../views/SetupPrefixMaterial.vue'
import SetupTemplate from '../views/SetupTemplate.vue'
import SetupTemplateProcessesType from '../views/SetupTemplateProcessesType.vue'
import SetupTemplateTestsType from '../views/SetupTemplateTestsType.vue'
import SetupTestType from '../views/SetupTestType.vue'
import SetupUserRole from '../views/SetupUserRole.vue'
import SetupProcessType from '../views/SetupProcessType.vue'
import Suppliers from '../views/Suppliers.vue'
import Supplier from '../views/Supplier.vue'
import TechFile from '../views/printFiles/TechFile.vue'
import Tests from '../views/Tests.vue'
import Test from '../views/Test.vue'
import Users from '../views/Users.vue'
import User from '../views/User.vue'
import FileUploadMock from '../views/FileUploadMock.vue'
import Page404 from '../views/Page404.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Login',
        component: Login
    },
    {
        path: '/home',
        name: 'Home',
        component: Home,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/contacts',
        name: 'Contacts',
        component: Contacts,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/contact/:id?',
        name: 'Contact',
        component: Contact,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/clients',
        name: 'Clients',
        component: Clients,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/client/:id?',
        name: 'Client',
        component: Client,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/distributors',
        name: 'Distributors',
        component: Distributors,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/distributor/:id?',
        name: 'Distributor',
        component: Distributor,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/family/:id?',
        name: 'Family',
        component: Family,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/ingredients',
        name: 'Ingredients',
        component: Ingredients,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/ingredient/:id?',
        name: 'Ingredient',
        component: Ingredient,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/materials',
        name: 'Materials',
        component: Materials,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/material/:id?',
        name: 'Material',
        component: Material,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/materialPrefix',
        name: 'MaterialPrefix',
        component: MaterialPrefix,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/nomenclature',
        name: 'Nomenclature',
        component: Nomenclature,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/formulations',
        name: 'Formulations',
        alias: '/formulas',
        component: Formulations,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/formulation/:id?',
        name: 'Formulation',
        alias: '/formula/:id?',
        component: Formulation,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/formulaPrefix',
        name: 'FormulaPrefix',
        component: FormulaPrefix,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/products',
        name: 'Products',
        component: Products,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/product/:id?',
        name: 'Product',
        component: Product,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/projects',
        name: 'Projects',
        component: Projects,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/project/:id?',
        name: 'Project',
        component: Project,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/regulations',
        name: 'Regulations',
        component: Regulations,
        meta: {
            requiresAuth: true
        },
        children: [{
                path: 'regulations',
                name: 'regulations',
                component: RegulationsRegulationsGrid
            },
            {
                path: 'families',
                name: 'families',
                component: RegulationsFamilyGrid
            },
            {
                path: 'nomenclatures',
                name: 'nomenclatures',
                component: RegulationsNomenclaturesGrid
            },
        ]
    },
    {
        path: '/techfile/:id?',
        name: 'Techfile',
        component: TechFile,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/regulation/:id?',
        name: 'Regulation',
        component: Regulation,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/setup-template',
        name: 'Setup-Template',
        component: SetupTemplate,
        meta: {
            requiresAuth: true
        },
        children: [{
                path: 'processes',
                name: 'processes',
                component: SetupTemplateProcessesType
            },
            {
                path: 'tests',
                name: 'tests',
                component: SetupTemplateTestsType
            },
        ]
    },
    {
        path: '/setup-prefix',
        name: 'Setup-Prefixe',
        component: SetupPrefix,
        meta: {
            requiresAuth: true
        },
        children: [{
                path: 'formula',
                name: 'formula',
                component: SetupPrefixFormula
            },
            {
                path: 'material',
                name: 'material',
                component: SetupPrefixMaterial
            },
        ]
    },
    {
        path: '/setup-test-type/:id?',
        name: 'Setup-Test-Type',
        component: SetupTestType,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/setup-user-role/:id?',
        name: 'Setup-User-Role',
        component: SetupUserRole,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/setup-process-type/:id?',
        name: 'Setup-Process-Type',
        component: SetupProcessType,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/suppliers',
        name: 'Suppliers',
        component: Suppliers,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/supplier/:id?',
        name: 'Supplier',
        component: Supplier,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/tests',
        name: 'Tests',
        alias: '/formulaTests',
        component: Tests,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/test/:id?',
        name: 'Test',
        alias: '/formulaTest/:id?',
        component: Test,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/users',
        name: 'Users',
        component: Users,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/user/:id?',
        name: 'User',
        component: User,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/fileUploadMock',
        name: 'FileUploadMock',
        component: FileUploadMock,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
        path: '/*',
        name: 'Page404',
        component: Page404
    }
]

export const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 };
    }
})

router.beforeEach((to, from, next) => {

  if (to.path != '/') {
      store.dispatch('setAccessedUrl', to.path);
  }

  if (store.getters.isAuthenticated == null) {
      axios.get('/userSession').then((response) => {

              store.commit('setPermission', response.data)

              var filter = "/search/findByUserName";
              var paramValue = {
                  "userName": response.data.userDetails.username,
              };
              axios.get("user" + filter, {
                  params: paramValue
              }).then((logUser) => {
                  store.commit('setUser', logUser.data)
                  i18n.locale = logUser.data.language.toLowerCase()
                  store.commit('setAuthenticated', true)

                  axios.get('/licenseValidate')
                  .then((licence)=>{
                    store.commit('setLicence', licence.data)

                    //to test with invalid licence uncomment
                    // licence.data.valid = false
                    // store.commit('setLicence', licence.data)

                    if (store.getters.getLicence.valid == false) {
                        next('/')
                        return
                    }
                    if (to.path == '/') {
                        next('/home')
                        return
                    }
                    next()
                    return
                  })
              })
          })
          .catch((error) => {
              console.log(error)
              store.commit('setAuthenticated', false)
              next('/')
              return
          })
  } else {
      if (to.matched.some((record) => record.meta.requiresAuth)) {
          if (store.getters.getLicence.valid == false) {
            if(from.path == '/'){
              return
            }
            next('/')
            return
          }
          if (store.getters.isAuthenticated) {
              next()
              return
          }
          next('/')
      }
      next()
  }

})



export default router
