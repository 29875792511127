<template>
<div v-can="'Read_Supplier'">

    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">

                <h2 class="text-left"><router-link to="/suppliers">{{$tc('general.supplier', 1)}}</router-link></h2>

                <LayoutCard :title="$t('general.informations')" active="true">

                    <template v-slot:header-options>
                        <DeleteButton v-can="'Delete_Supplier'" endpoint="supplier" :dataToDisplay="objectData.supplierName" searchValue="null" :relationship="$store.state.supplier.associatedData"></DeleteButton>
                    </template>

                    <div class="loader" v-if="loading"></div>

                    <fieldset :disabled="loading">
                        <form @change="save">

                            <div class="form-row">
                                <div class="form-group col-md-10">
                                    <label for="inputCompanyName">{{$t('distributor.companyName')}}</label>
                                    <b-form-input v-can:input="'Update_Supplier'" type="text" class="form-control" id="inputCompanyName" :placeholder="$t('distributor.companyName')" :state="validateState($v.objectData.supplierName)"
                                        @input="getSupplierName(objectData.supplierName)" :trim="true" v-model="$v.objectData.supplierName.$model" />
                                    <div class="invalid-feedback" id="company-name-required" v-if="!$v.objectData.supplierName.required">{{$t('errorMsg.required')}}</div>
                                    <div class="invalid-feedback" v-if="!$v.objectData.supplierName.isSupplierNameUnique">This supplier already exist</div>
                                </div>

                                <div class="form-group col-md-2">
                                    <label>{{$t('general.status')}}</label>
                                    <v-select id="inputClientStatus" placeholder="Select a status" v-model="$v.objectData.status.$model" :reduce="status => status.id" :selectable="status => status.selectable" :clearable="false"
                                        :options="supplierStatus" label="name" @input="save" :class="validateStatus($v.objectData.status)" class="vselect">
                                    </v-select>
                                    <!-- <b-form-select id="select-supplier-status" v-model="$v.objectData.status.$model" :state="validateState($v.objectData.status)" value-field="id" text-field="name" :options="supplierStatus">
                                        <template v-slot:first>
                                            <b-form-select-option :value="''" disabled>{{$t('general.selectStatus')}}</b-form-select-option>
                                        </template>
                                    </b-form-select> -->
                                    <div class="error" id="status-required" v-if="!$v.objectData.status.required && $v.objectData.status.$dirty">{{$t('errorMsg.required')}}</div>
                                </div>

                            </div>

                            <div class="form-group">
                                <label for="inputEmail">{{$t('contact.email')}}</label>
                                <input v-can:input="'Update_Supplier'" type="text" class="form-control" id="inputEmail" :placeholder="$t('contact.email')" v-model="objectData.email">
                            </div>

                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <label for="inputPhone">{{$t('contact.phoneNumber')}}</label>
                                    <input v-can:input="'Update_Supplier'" type="text" class="form-control" id="inputPhone" :placeholder="$t('contact.phoneNumber')" v-model="objectData.phoneNumber">
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="inputFax">{{$t('contact.faxNumber')}}</label>
                                    <input v-can:input="'Update_Supplier'" type="text" class="form-control" id="inputFax" :placeholder="$t('contact.faxNumber')" v-model="objectData.faxNumber">
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="inputAdress">{{$t('contact.adress')}}</label>
                                <input v-can:input="'Update_Supplier'" type="text" class="form-control" id="inputAdress" placeholder="Street name" v-model="objectData.address">
                            </div>

                            <div class="form-row">
                                <div class="form-group col-md-4">
                                    <label for="inputCity">{{$t('contact.city')}}</label>
                                    <input v-can:input="'Update_Supplier'" type="text" class="form-control" id="inputCity" :placeholder="$t('contact.city')" v-model="objectData.city">
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="inputState">{{$t('contact.state')}}</label>
                                    <input v-can:input="'Update_Supplier'" type="text" class="form-control" id="inputState" :placeholder="$t('contact.state')" v-model="objectData.state">
                                </div>
                                <div class="form-group col-md-2">
                                    <label for="inputZip">{{$t('contact.zipCode')}}</label>
                                    <input v-can:input="'Update_Supplier'" type="text" class="form-control" id="inputZip" :placeholder="$t('contact.zipCode')" v-model="objectData.zipCode">
                                </div>

                                <div class="form-group col-md-3">

                                    <label for="inputCountry">{{$t('contact.country')}}</label>
                                    <b-form-input v-can:input="'Update_Supplier'" id="input-supplier-country" list="country-list" :placeholder="$t('contact.country')" autocomplete="off" :state="validateCountry()" :lazy="true" :trim="true"
                                        v-model="$v.selectedSupplierCountryName.$model"></b-form-input>
                                    <div class="invalid-feedback" v-if="!$v.selectedSupplierCountryName.required && $v.selectedSupplierCountryName.$dirty">{{$t('errorMsg.required')}}</div>
                                    <div class="invalid-feedback" id="country-must-exist" v-if="!$v.selectedSupplierCountryId.countryMustExist && $v.selectedSupplierCountryName.$dirty">Country must exist</div>
                                    <datalist id="country-list">
                                        <option v-for="country in countries" :key="country.id" field-value="country.id"> {{ country.name }}</option>
                                    </datalist>

                                </div>
                            </div>

                        </form>
                    </fieldset>

                </LayoutCard>

                <LayoutCard :title="$tc('general.contact', 2)">

                    <ListingContact endpoint="supplier" :contacts.sync="contacts" :isContactSet="isContactSet" :nameToDisplay="objectData.supplierName" @contactSet="isContactSet = true" @saved="savedSucces" :totalRows="contacts.length">
                    </ListingContact>
                    <div class="error small" id="contact-required" v-if="!$v.isContactSet.selected && $v.$anyError">{{$t('errorMsg.required')}}</div>

                </LayoutCard>

                <LayoutCard :title="$tc('general.distributor', 2)">

                    <div v-if="loading" class="loader"></div>

                    <div v-else>

                        <ListingDistributor endpoint="supplier" :distributors.sync="distributors" :nameToDisplay="objectData.supplierName" @saved="savedSucces" :totalRows="distributors.length">
                        </ListingDistributor>

                    </div>

                </LayoutCard>

                <!-- <LayoutCard v-if="this.$route.params.id" :title="$tc('general.material', 2)">

                    <div class="loader" v-if="loading"></div>

                    <div v-else>


                        <div>

                            Here a list of raw materials
                            <br />
                            Is it really needed ????

                        </div>

                    </div>

                </LayoutCard> -->

                <div v-if="isMod">

                    <SaveCancelDeleteButtons permission="Create_Supplier" @clicked="getClicked" :isError="$v.$anyError"></SaveCancelDeleteButtons>

                </div>

                <div class="fixed-top mt-3 d-flex justify-content-center">
                    <b-alert :show="dismissCountDown" dismissible fade :variant="alertType" @dismiss-count-down="countDownChanged">
                        {{ feedbackMessage }}
                    </b-alert>
                </div>

                <LayoutCard v-if="!loading && this.$route.params.id" :title="$t('general.history')">

                    <History :dataType="endpoint" :addBy="objectData.addBy" :addDate="objectData.addDate" :lastModBy="objectData.modBy" :lastMod="objectData.modDate" />

                </LayoutCard>

            </div>
        </div>
    </div>

    <ModalWindow v-show="openedModal === 'distributor'" :title="$t('general.search')" @close="openedModal = null">

        We need to implement the distributor here

    </ModalWindow>


</div>
</template>

<script>
import axios from 'axios';
import History from '../components/History.vue';
import LayoutCard from '../components/LayoutCard.vue';
import SaveCancelDeleteButtons from '../components/SaveCancelDeleteButtons.vue';
import DeleteButton from '../components/DeleteButton.vue';
import ModalWindow from '../components/ModalWindow.vue';
import ListingContact from '../components/ListingContact.vue';
import ListingDistributor from '../components/ListingDistributor.vue';
import {
    required,
    minLength,
    email
} from 'vuelidate/lib/validators'

import {
    mapActions
} from "vuex";

import Feedback from '../mixins/Feedback.js'
import CommonVariablesAndFunctions from '../mixins/CommonVariablesAndFunctions.js'

function isSupplierNameUnique(value) {
    if (this.suppliers.filter(e => e.supplierName.toLowerCase() === value.toLowerCase()).length == 0) {
        return true
    }
    return false
}

window.axios = require("axios");

const countryMustExist = (value) => value != ""

export default {
    name: 'Distributor',

    mixins: [Feedback, CommonVariablesAndFunctions],

    components: {
        History,
        ListingContact,
        SaveCancelDeleteButtons,
        DeleteButton,
        LayoutCard,
        ListingDistributor,
        ModalWindow,
    },

    data() {
        return {
            title: "Supplier",
            endpoint: "supplier",

            openedModal: null,

            suppliers: [],

            countries: [],
            selectedSupplierCountryName: "",

            contacts: [],
            isContactSet: false,

            distributors: [],
            selectedDistributorName: [],
            distributorStatusName: "",

            distributorsField: [{
                    key: 'distributorName',
                    label: this.$t('distributor.distributorName'),
                },
                {
                    key: 'show_details',
                    label: this.$t('general.details'),
                },
                {
                    key: 'options',
                    label: this.$t('general.options'),
                }
            ],

            objectData: {
                addBy: "",
                addDate: "",
                modBy: "",
                modDate: null,
                supplierName: "",
                phoneNumber: "",
                faxNumber: "",
                email: "",
                address: "",
                city: "",
                zipCode: "",
                state: "",
                country: "",
                status: "",
                distributor: [],
                contact: [{
                    addBy: "",
                    addDate: "",
                    modBy: "",
                    modDate: null,
                    firstName: "",
                    lastName: "",
                    role: "",
                    phoneNumber: "",
                    faxNumber: "",
                    email: "",
                    address: "",
                    city: "",
                    zipCode: "",
                    state: "",
                    country: "",
                    status: ""
                }],
            },

        };
    },

    computed: {

        selectedSupplierCountryId: function() {

            if (this.selectedSupplierCountryName != "") {
                if (this.countries.filter(country => country.name == this.selectedSupplierCountryName).length != 0) {
                    let id = this.countries.filter(country => country.name == this.selectedSupplierCountryName)[0].id
                    return id
                } else {
                    return ""
                }
            }
            return ""
        },

        supplierStatus: function() {

            if (this.$store.getters.getStatusFile.supplier.length == 0) {
                this.statusFile('supplier')
            }

            return this.$store.getters.getStatusFile.supplier
        },

    },

    methods: {
        ...mapActions(["statusFile"]),

        validateCountry() {
            if (this.$v.selectedSupplierCountryName.$dirty && this.$v.selectedSupplierCountryName.$error) {
                return false
            }
            if (this.$v.selectedSupplierCountryName.$dirty != "" && this.$v.selectedSupplierCountryId.$invalid) {
                return false
            }
            if (this.$v.selectedSupplierCountryName.$dirty) {
                return true
            }
            return null
        },

        saveDistributor() {
            if (this.$route.params.id) {
                this.save()
            }
        },

        async getData() {
            this.loading = true;

            try {
                let response = await axios.get(this.endpoint + '/' + this.$route.params.id)
                this.objectData = response.data

                //get the country name
                let country = await axios.get('country/' + response.data.country)
                this.selectedSupplierCountryName = country.data.name

                let responseContact = await axios.get(this.endpoint + '/' + this.$route.params.id + '/contacts')

                if (responseContact.data._embedded.contact.length != 0) {

                    this.contacts = responseContact.data._embedded.contact

                    this.isContactSet = true;
                }

                let responseDistributor = await axios.get(this.endpoint + '/' + this.$route.params.id + '/distributors')

                if (responseDistributor.data._embedded.distributor.length != 0) {
                    this.distributors = responseDistributor.data._embedded.distributor
                }

                if (response.data.distributor.length != 0) {
                    axios
                        .get('statusFile/' + response.data.distributor[0].status)
                        .then((status) => {
                            this.distributorStatusName = status.data.name
                        })
                }

                this.loading = false;
                this.isMod = false;

            } catch (error) {
                console.log(error);
                this.errorAlert(error);
            }
        },

        processForm() {

            let data = {
                supplierName: this.objectData.supplierName.charAt(0).toUpperCase() + this.objectData.supplierName.slice(1),
                status: this.objectData.status,
                email: this.objectData.email,
                phoneNumber: this.objectData.phoneNumber,
                faxNumber: this.objectData.faxNumber,
                address: this.objectData.address,
                city: this.objectData.city,
                state: this.objectData.state,
                zipCode: this.objectData.zipCode,
                country: this.selectedSupplierCountryId,
            };

            return data

        },

        async postData(endpoint, data) {
            try {

                //Post informations data
                let supplier = await axios.post("supplier", data);

                let distributorsURL = supplier.data._links.distributors.href
                let id = supplier.data._links.self.href.split("/").pop();

                //put contacts
                let contactsLink
                for (let i = 0; i < this.contacts.length; i++) {
                    contactsLink += this.contacts[i]._links.self.href + '\n'
                }

                await axios.put('supplier/' + id + '/contacts', contactsLink, {
                    headers: {
                        "Content-type": "text/uri-list"
                    }
                })

                //put Distributor
                if (this.distributors.length != 0) {
                    let distributorsLink
                    for (let i = 0; i < this.distributors.length; i++) {
                        distributorsLink += this.distributors[i]._links.self.href + '\n'
                    }

                    await axios.put(distributorsURL, distributorsLink, {
                        headers: {
                            "Content-type": "text/uri-list"
                        }
                    })
                }

                this.isMod = false;
                this.succesAlert()
                this.$router.push(this.endpoint + '/' + id);
                this.getData();

            } catch (error) {
                this.errorAlert(error);
            }

        },

        putData(endpoint, data) {

            //Update informations
            // update contact
            // see LisitingContact.vue
            // update distributors
            // see LisitingDistributor.vue

            axios.put(endpoint, data)
                .then(() => {
                    this.isMod = false;
                    this.succesAlert()
                    this.getData();
                })
                .catch((error) => {
                    console.log(error);
                    this.errorAlert(error);
                })

        },

        savedSucces() {
            this.succesAlert()
        },

        showModal(type) {
            this.openedModal = type
        },

        async getSupplierName(input) {
            if (this.objectData.supplierName.length > 1) {
                var filter = "/search/findBySupplierNameContainingIgnoreCaseOrderBySupplierName";
                var paramValue = {
                    "supplierName": input,
                };
                let response = await axios.get("supplier" + filter, {
                    params: paramValue
                })
                this.suppliers = response.data._embedded.supplier;
            }
        },

        async loadOptionFields() {

            axios
                .get('country')
                .then((response) => {
                    this.countries = response.data._embedded.hashMaps
                })
        },


    },
    //launch once the view is created
    created() {

        this.loadOptionFields()


        if (this.$route.params.id) {
            this.getData();
        } else {
            this.loading = false;
        }
    },

    validations: {

        objectData: {
            supplierName: {
                required,
                minLength: minLength(1),
                isSupplierNameUnique
            },
            email: {
                email
            },
            status: {
                required,
            },
        },
        selectedSupplierCountryName: {
            required,
        },
        selectedSupplierCountryId: {
            countryMustExist,
        },
        isContactSet: {
            selected: value => value === true
        }
    }
};
</script>

<style scoped>
/* .form-group {
    text-align: left;
    font-weight: bold;
} */

.table-responsive {
    overflow-x: visible !important;
    overflow-y: visible !important;
}

.error {
    font-weight: bold;
    color: crimson;
}
</style>
