<template>
<div v-can="'Read_Client'">

    <div class="container-fluid">


        <h2 class="text-left"><router-link to="/clients">{{$tc('general.client', 1)}}</router-link></h2>

        <LayoutCard :title="$t('general.informations')" active="true">

            <template v-slot:header-options>
                <DeleteButton v-can="'Delete_Client'" endpoint="client" :dataToDisplay="objectData.companyName" searchValue="null" :relationship="$store.state.client.associatedData"></DeleteButton>
            </template>

            <div class="loader" v-if="loading">
            </div>

            <fieldset :disabled="loading">
                <form @change="save">
                    <div class="form-row">
                        <div class="form-group col-md-10">
                            <label for="inputCompanyName">{{$t('distributor.companyName')}}</label>
                            <b-form-input v-can:input="'Update_Client'" type="text" class="form-control" :state="validateState($v.objectData.companyName)" id="inputCompanyName" placeholder="First name" @input="getCompanyName(objectData.companyName)"
                                :trim="true" v-model="$v.objectData.companyName.$model" />
                            <div class="invalid-feedback" id="company-name-required" v-if="!$v.objectData.companyName.required">{{$t('errorMsg.required')}}</div>
                            <div class="invalid-feedback" v-if="!$v.objectData.companyName.isCompanyNameUnique">This client already exist</div>
                        </div>

                        <div class="form-group col-md-2">
                            <label>{{$t('general.status')}}</label>
                            <v-select id="inputClientStatus" placeholder="Select a status" v-model="$v.objectData.status.$model" :reduce="status => status.id" :selectable="status => status.selectable" :clearable="false" :options="clientStatus" label="name" @input="save" :class="validateStatus($v.objectData.status)"
                                class="vselect">
                            </v-select>
                            <div class="error" id="status-required" v-if="!$v.objectData.status.required && $v.objectData.status.$dirty">{{$t('errorMsg.required')}}</div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="inputEmail">{{$t('contact.email')}}</label>
                        <input v-can:input="'Update_Client'" type="text" class="form-control" id="inputEmail" :placeholder="$t('contact.email')" v-model="objectData.email">
                    </div>

                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label for="inputPhone">{{$t('contact.phoneNumber')}}</label>
                            <input v-can:input="'Update_Client'" type="text" class="form-control" id="inputPhone" :placeholder="$t('contact.phoneNumber')" v-model="objectData.phoneNumber">
                        </div>
                        <div class="form-group col-md-6">
                            <label for="inputFax">{{$t('contact.faxNumber')}}</label>
                            <input v-can:input="'Update_Client'" type="text" class="form-control" id="inputFax" :placeholder="$t('contact.faxNumber')" v-model="objectData.faxNumber">
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="inputAdress">{{$t('contact.adress')}}</label>
                        <input v-can:input="'Update_Client'" type="text" class="form-control" id="inputAdress" placeholder="1234 Main street" v-model="objectData.address">
                    </div>

                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="inputCity">{{$t('contact.city')}}</label>
                            <input v-can:input="'Update_Client'" type="text" class="form-control" id="inputCity" :placeholder="$t('contact.city')" v-model="objectData.city">
                        </div>
                        <div class="form-group col-md-3">
                            <label for="inputState">{{$t('contact.state')}}</label>
                            <input v-can:input="'Update_Client'" type="text" class="form-control" id="inputState" :placeholder="$t('contact.state')" v-model="objectData.state">
                        </div>
                        <div class="form-group col-md-2">
                            <label for="inputZip">{{$t('contact.zipCode')}}</label>
                            <input v-can:input="'Update_Client'" type="text" class="form-control" id="inputZip" :placeholder="$t('contact.zipCode')" v-model="objectData.zipCode">
                        </div>

                        <div class="form-group col-md-3">
                            <label for="inputCountry">{{$t('contact.country')}}</label>
                            <b-form-input v-can:input="'Update_Client'" id="inputCountry" list="country-list" :placeholder="$t('contact.country')" autocomplete="off" :state="validateCountry()" :lazy="true" :trim="true" v-model="$v.selectedClientCountryName.$model"></b-form-input>
                            <div class="invalid-feedback" v-if="!$v.selectedClientCountryName.required && $v.selectedClientCountryName.$dirty">{{$t('errorMsg.required')}}</div>
                            <div class="invalid-feedback" id="country-must-exist" v-if="!$v.selectedClientCountryId.countryMustExist && $v.selectedClientCountryName.$dirty">Country must exist</div>
                            <datalist id="country-list">
                                <option v-for="country in countries" :key="country.id" field-value="country.id"> {{ country.name }}</option>
                            </datalist>
                        </div>
                    </div>

                </form>
            </fieldset>


        </LayoutCard>

        <LayoutCard :title="$tc('general.contact', 2)" active="true">

            <ListingContact endpoint="client" :contacts.sync="contacts" :isContactSet="isContactSet" :nameToDisplay="objectData.companyName" @contactSet="isContactSet = true" @saved="contactSaved" :totalRows="contacts.length"></ListingContact>
            <div class="error small" id="contact-required" v-if="!$v.isContactSet.selected && $v.$anyError">{{$t('errorMsg.required')}}</div>

        </LayoutCard>

        <div v-if="isMod">

            <SaveCancelDeleteButtons permission="Create_Client" @clicked="getClicked" :isError="$v.$anyError"></SaveCancelDeleteButtons>

        </div>

        <div class="fixed-top mt-3 d-flex justify-content-center">
            <b-alert :show="dismissCountDown" dismissible fade :variant="alertType" @dismiss-count-down="countDownChanged">
                {{ feedbackMessage }}
            </b-alert>
        </div>

        <LayoutCard v-if="!loading && this.$route.params.id" :title="$t('layoutCard.associatedProjects')">
            <ListingGrid :column="projectColumn" :endpoint=' "/client/" + this.$route.params.id + "/projects" ' dataObject="project" redirectUrl="/project" :parentData="this.$route.params.id"></ListingGrid>
        </LayoutCard>


        <LayoutCard v-if="!loading && this.$route.params.id" :title="$t('general.history')">
            <History :dataType="endpoint" :addBy="objectData.addBy" :addDate="objectData.addDate" :lastModBy="objectData.modBy" :lastMod="objectData.modDate" />
        </LayoutCard>


    </div>

</div>
</template>

<script>
import axios from 'axios'
import History from '../components/History.vue'
import LayoutCard from '../components/LayoutCard.vue'
import SaveCancelDeleteButtons from '../components/SaveCancelDeleteButtons.vue'
import DeleteButton from '../components/DeleteButton.vue'
import ListingGrid from '../components/ListingGrid.vue';
import ListingContact from '../components/ListingContact.vue';
import {
    required,
    minLength,
    email
} from 'vuelidate/lib/validators';

import {
    mapActions
} from "vuex";

function isCompanyNameUnique(value) {
    if (this.clients.filter(e => e.companyName.toLowerCase() === value.toLowerCase()).length == 0) {
        return true
    }
    return false
}

import Feedback from '../mixins/Feedback.js'
import CommonVariablesAndFunctions from '../mixins/CommonVariablesAndFunctions.js'

window.axios = require("axios");

const countryMustExist = (value) => value != ""

export default {
    name: 'Client',
    endpoint: "client",

    mixins: [Feedback, CommonVariablesAndFunctions],

    components: {
        History,
        LayoutCard,
        SaveCancelDeleteButtons,
        DeleteButton,
        ListingGrid,
        ListingContact
    },

    data() {
        return {
            title: "Client",
            endpoint: "client",

            clients: [],

            countries: [],
            selectedClientCountryName: "",

            contactCountry: {
                name: "",
                id: ""
            },

            contacts: [],
            isContactSet: false,

            objectData: {
                addBy: "",
                addDate: "",
                modBy: "",
                modDate: "",
                companyName: "",
                phoneNumber: "",
                faxNumber: "",
                email: "",
                address: "",
                city: "",
                zipCode: "",
                state: "",
                country: "",
                status: "",
                contact: [{
                    addBy: "",
                    addDate: "",
                    modBy: "",
                    modDate: "",
                    firstName: "",
                    lastName: "",
                    role: "",
                    phoneNumber: "",
                    faxNumber: "",
                    email: "",
                    address: "",
                    city: "",
                    zipCode: "",
                    state: "",
                    country: "",
                    status: ""
                }],
            },

            projectColumn: [{
                    key: 'projectName',
                    sortable: true
                },
                {
                    key: 'targetDate',
                    sortable: true
                },
                {
                    key: 'status',
                    sortable: true
                },
                {
                    key: 'goTo',
                    sortable: false
                },
            ]

        };
    },

    computed: {

        selectedClientCountryId: function() {

            if (this.selectedClientCountryName != "") {
                if (this.countries.filter(country => country.name == this.selectedClientCountryName).length != 0) {
                    let id = this.countries.filter(country => country.name == this.selectedClientCountryName)[0].id
                    return id
                } else {
                    return ""
                }
            }
            return ""
        },

        clientStatus: function() {

            if (this.$store.getters.getStatusFile.client.length == 0) {
                this.statusFile('client')
            }

            return this.$store.getters.getStatusFile.client
        },

    },

    methods: {
        ...mapActions(["statusFile"]),

        validateCountry() {
            if (this.$v.selectedClientCountryName.$dirty && this.$v.selectedClientCountryName.$error) {
                return false
            }
            if (this.$v.selectedClientCountryName.$dirty != "" && this.$v.selectedClientCountryId.$invalid) {
                return false
            }
            if (this.$v.selectedClientCountryName.$dirty) {
                return true
            }
            return null
        },

        async getData() {
            this.loading = true;

            let response = await axios.get(this.endpoint + '/' + this.$route.params.id)

            this.objectData = response.data

            axios
                .get('country/' + response.data.country)
                .then((country) => {
                    this.selectedClientCountryName = country.data.name
                })

            let responseContact = await axios.get(this.endpoint + '/' + this.$route.params.id + '/contacts')

            if (responseContact.data._embedded.contact.length != 0) {

                this.contacts = responseContact.data._embedded.contact

                this.isContactSet = true;
            }

            this.loading = false;
            this.isMod = false;

        },


        processForm() {
            let client = {
                companyName: this.objectData.companyName.charAt(0).toUpperCase() + this.objectData.companyName.slice(1),
                status: this.objectData.status,
                email: this.objectData.email,
                phoneNumber: this.objectData.phoneNumber,
                faxNumber: this.objectData.faxNumber,
                address: this.objectData.address,
                city: this.objectData.city,
                zipCode: this.objectData.zipCode,
                country: this.selectedClientCountryId,
            };

            return client

        },

        async getCompanyName(input) {
            if (input.length > 1) {
                var filter = "/search/findByCompanyNameContainingIgnoreCaseOrderByCompanyName";
                var paramValue = {
                    "companyName": input,
                };
                let response = await axios.get("client" + filter, {
                    params: paramValue
                })
                this.clients = response.data._embedded.client;
            }
        },

        async postData(endpoint, data) {

            try {
                //Post informations data
                let client = await axios.post(endpoint, data);

                let id = client.data._links.self.href.split("/").pop();

                //put contacts
                let contactsLink
                for (let i = 0; i < this.contacts.length; i++) {
                    contactsLink += this.contacts[i]._links.self.href + '\n'
                }

                await axios.put('client/' + id + '/contacts', contactsLink, {
                    headers: {
                        "Content-type": "text/uri-list"
                    }
                })

                this.isMod = false;
                this.succesAlert()
                this.$router.push(this.endpoint + '/' + id);
                this.getData();

            } catch (error) {
                console.log(error);
                this.errorAlert(error);
            }


        },

        async putData(endpoint, data) {
            try {
                //Update informations
                await axios.put(endpoint, data);

                // update contact
                // see LisitingContact.vue

                this.isMod = false;
                this.succesAlert()

            } catch (error) {
                this.errorAlert(error);
            }

        },

        contactSaved() {
            this.succesAlert()
        },

        async loadOptionFields() {

            axios
                .get('country')
                .then((response) => {
                    this.countries = response.data._embedded.hashMaps
                })
        },

    },

    created() {

        this.loadOptionFields()

        if (this.$route.params.id != undefined) {
            this.getData();
        } else {
            this.loading = false;
        }
    },

    validations: {

        objectData: {
            companyName: {
                required,
                minLength: minLength(1),
                isCompanyNameUnique
            },
            email: {
                email
            },
            status: {
                required,
            },
            contact: {
                required,
            }
        },
        selectedClientCountryName: {
            required,
        },
        selectedClientCountryId: {
            countryMustExist,
        },
        isContactSet: {
            selected: value => value === true
        }

    }
};
</script>

<style scope>
.form-group {
    text-align: left;
    font-weight: bold;
}

.error {
    color: crimson;
    font-weight: bold;
    font-size: small;
}
</style>
