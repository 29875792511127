import axios from "axios";

const state = {

    statusFile: {
        client: [],
        contact: [],
        distributor: [],
        supplier:[]
    },

    statusFormulaProjectProduct: {
        project: [],
        product: [],
        formula: [],
    },

    statusMaterialINCIRegulation: {
        rawMaterial:[],
        inci: [],
        regulation: [],
    }

};
const getters = {
    getStatusFile: state => {
        return state.statusFile
    },

    getStatusFormulaProjectProduct: state => {
        return state.statusFormulaProjectProduct
    },

    getStatusMaterialINCIRegulation: state => {
        return state.statusMaterialINCIRegulation
    },

};
const actions = {

    statusFile({
        commit,
        getters
    }, fileType) {
        axios.get('/statusFile')
            .then((response) => {
                let status = response.data._embedded.hashMaps

                for(let i = 0; i< status.length; i++){
                    if(getters.getPermission.userDetails.authorities.some(e => e.role.toUpperCase() == 'STATUSFILE_'+status[i].id.replace("_", "")+'_'+fileType.toUpperCase())){
                        status[i].selectable = true
                    }else{
                        status[i].selectable = false
                    }

                }
                commit('setStatusFile', {fileType, status })
            })
    },

    statusFormulaProjectProduct({
        commit,
        getters
    }, fileType) {
        axios.get('/statusFormulaProjectProduct')
            .then((response) => {
                let status = response.data._embedded.hashMaps

                for(let i = 0; i< status.length; i++){
                    if(getters.getPermission.userDetails.authorities.some(e => e.role.toUpperCase() == 'STATUSFORMULA_'+status[i].id.replace("_", "")+'_'+fileType.toUpperCase())){
                        status[i].selectable = true
                    }else{
                        status[i].selectable = false
                    }

                }
                commit('setStatusFormulaProjectProduct', {fileType, status })
            })
    },

    statusMaterialINCIRegulation({
        commit,
        getters
    }, fileType) {
        axios.get('/statusMaterialINCIRegulation')
            .then((response) => {
                let status = response.data._embedded.hashMaps

                for(let i = 0; i< status.length; i++){
                    if(getters.getPermission.userDetails.authorities.some(e => e.role.toUpperCase() == 'STATUSMATERIAL_'+status[i].id.replace("_", "")+'_'+fileType.toUpperCase())){
                        status[i].selectable = true
                    }else{
                        status[i].selectable = false
                    }

                }
                commit('setStatusMaterialINCIRegulation', {fileType, status })
            })
    },

};
const mutations = {

    setStatusFile(state, {fileType, status}) {
        state.statusFile[fileType] = status
    },

    setStatusFormulaProjectProduct(state, {fileType, status}) {
        state.statusFormulaProjectProduct[fileType] = status
    },

    setStatusMaterialINCIRegulation(state, {fileType, status}) {
        state.statusMaterialINCIRegulation[fileType] = status
    },

    setAllStatus(state, value) {
        Object.keys(state.statusFile).forEach(v => state.statusFile[v] = value)
        Object.keys(state.statusFormulaProjectProduct).forEach(v => state.statusFormulaProjectProduct[v] = value)
        Object.keys(state.statusMaterialINCIRegulation).forEach(v => state.statusMaterialINCIRegulation[v] = value)
    }


};
export default {
    state,
    getters,
    actions,
    mutations
};
